import ClassicEditor from "@digitale-lernwelten/ckeditor5-build-dilewe";
import type { CKEditorInstance } from "@digitale-lernwelten/ckeditor5-build-dilewe";

export const initRTE = async (element:HTMLElement, initialData = "", placeholder = ""): Promise<CKEditorInstance> => {
	return await ClassicEditor.create(element, {
		// https://ckeditor.com/docs/ckeditor5/latest/features/toolbar/toolbar.html#extended-toolbar-configuration-format
		toolbar: {
			items: [
				'bold', 'italic', 'underline', 'strikethrough', '|',
				'fontColor', 'fontBackgroundColor', '|',
				'numberedList', 'bulletedList', '|',
				'undo', 'redo'
			],
			shouldNotGroupWhenFull: true
		},
		// Changing the language of the interface requires loading the language file using the <script> tag.
		language: 'de',
		initialData,
		placeholder,

		removePlugins: [
			'CKBox',
			'CKFinder',
			'EasyImage',
			'RealTimeCollaborativeComments',
			'RealTimeCollaborativeTrackChanges',
			'RealTimeCollaborativeRevisionHistory',
			'PresenceList',
			'Comments',
			'TrackChanges',
			'TrackChangesData',
			'RevisionHistory',
			'Pagination',
			'WProofreader',
			'MathType',
			'RestrictedEditingMode'
		]
	});
};
