export const injectNavEntries = () => {
	const footernav = document.querySelector<HTMLElement>(`section[content-type-id="2558411"]`);
	const navItem = document.querySelector<HTMLElement>(`#navigation li[page-id="2265670"]`);
	if (!footernav || !navItem) { return;}

	const allList = Array.from(footernav.querySelectorAll<HTMLElement>("ul"));
	for (const uList of allList) {
		uList.classList.add("nav-format-3");
		navItem.append(uList);
	}
};

setTimeout(injectNavEntries, 0);
