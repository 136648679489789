import "./text-and-image.css";

export const initFigureRow = (row:HTMLElement) => {
	const cols = row.children;
	const ratios: number[] = [];
	for(const col of Array.from(cols)){
		const img = col.querySelector("img");
		if(!img){break;}
		const width = parseInt(img.getAttribute("width") || img.getAttribute("raw-width") || "0");
		const height = parseInt(img.getAttribute("height") || img.getAttribute("raw-height") || "0");
		if((width <= 0) || (height <= 0)){return;}
		ratios.push(width/height);
	}
	if(ratios.length !== cols.length){return;}
	const sum = ratios.reduce((a,b) => (a + b),0);
	const widths = ratios.map(v => (v/sum) * 100.0);
	widths.forEach((v,i) => {
		const curCol = cols[i];
		if(curCol instanceof HTMLElement){
			curCol.style.width = `${v}%`;
		}
	});
};
setTimeout(() => {
	document.querySelectorAll<HTMLElement>("figure-row").forEach(initFigureRow);
},0);

