import { addHideElementContentHandler } from "../../_helper";
import { continueWhenAllowed } from "../../privacy/privacy";
import { canUseEmbeds, embedsAllowed } from "../../privacy/privacy";
import t from "../../i18n";
import configuration from "../../../configuration";
import { isLoggedIn } from "../../user-group-management";

import "./embedding.css";

const calculateAspectRatio = (() => {
	const customAspectRatio = (customEle: HTMLElement, src:string) => {
		if (!src.includes("player.ntr.nl/")) { return null;}
		const hardCodedWidth = customEle.getAttribute("hc-width");
		const hardCodedHeight = customEle.getAttribute("hc-height");
		if(!hardCodedWidth || !hardCodedHeight ){return null;}
		const parsedWidth = parseInt(hardCodedWidth);
		const parsedHeight = parseInt(hardCodedHeight);
		return { parsedWidth, parsedHeight };
	};

	const plistGetf = (arr:string[], key:string) => {
		for(let i=0;i<arr.length-1;i++){
			if(arr[i] === key){return arr[i+1];}
		}
		return null;
	};

	return (ele:HTMLElement) => {
		const src = ele.getAttribute("src");
		if(!src || !(src.includes("geogebra.org/") || src.includes("player.ntr.nl/"))){return;}
		const srcArr = src.split("/");
		let width;
		let height;
		if (src.includes("geogebra.org/")) {
			width = parseInt(plistGetf(srcArr,"width") || "0");
			height = parseInt(plistGetf(srcArr,"height") || "0");
		} else {
			width = customAspectRatio(ele, src)?.parsedWidth;
			height = customAspectRatio(ele, src)?.parsedHeight;
		}
		if(!width || !height ){ return;}
		const ar = width / height;
		ele.setAttribute("aspect-ratio", String(ar));
	};
})();

export const isThirdPartyHref = (url:string) => {
	const arr = url.split("/");
	if(arr.length < 3){return true;}
	if(url.indexOf("usesExternalSources=1") >= 0){return true;}
	const whitelist = new Set();
	whitelist.add("h5p.test-dilewe.de");
	whitelist.add("jura-museum-h5p.dilewe.de");
	whitelist.add("h5p-hessen.test-dilewe.de");
	if(whitelist.has(arr[2])){return false;}
	return true;
};


const isLoaded = (ele:HTMLElement) => {
	const parent = ele.parentElement;
	if (!parent) {return false;}
	const wrap = parent.querySelector("iframe-wrapper");
	if (!wrap) { return false;}
	return true;
};

// Example embed: https://h5p.test-dilewe.de/wp-admin/admin-ajax.php?action=h5p_embed&id=1374
// Example edit: https://h5p.test-dilewe.de/wp-admin/admin.php?page=h5p&task=show&id=3392
const getH5PEditUrl = (url:string) => {
	if(!url){
		return url;
	}
	const p = new URL(url);
	if(p.pathname !== "/wp-admin/admin-ajax.php"){
		return "";
	}
	if(p.searchParams.get("action") !== "h5p_embed"){
		return "";
	}
	const id = p.searchParams.get("id");
	if(!id){
		return "";
	}
	return `https://${p.hostname}/wp-admin/admin.php?page=h5p&task=show&id=${id}`;
};

export const showLazyIframe = async (rawEle:Element) => {
	if (!rawEle || !(rawEle instanceof HTMLElement)) {return;}
	const ele:HTMLElement = rawEle;
	if(ele.classList.contains("hidden-embedding-placeholder")){return;}

	if(configuration.onlyAllowEmbedsForLoggedInUsers && !isLoggedIn()){
		ele.classList.add("disallow-until-login");
		ele.innerText = t().onlyAllowEmbedsForLoggedInUsers;
		return;
	} else {
		ele.classList.remove("disallow-until-login");
		ele.innerText = '';
	}

	if(!embedsAllowed() && isThirdPartyHref(ele.getAttribute("src") || "")){
		ele.addEventListener("click", canUseEmbeds);
		ele.classList.add("waiting-for-approval");
		// new t(). moved from css pseudo for translation
		const text = document.createElement("lazy-text");
		text.innerText = t().externalEmbed;
		ele.append(text);
		await continueWhenAllowed();
		if (isLoaded(ele)) { return;}
		ele.removeEventListener("click", canUseEmbeds);
		ele.classList.remove("waiting-for-approval");
	}

	const iframeWrapper = document.createElement("iframe-wrapper");
	const newEle = document.createElement("iframe");
	newEle.setAttribute("frameborder","0");
	newEle.setAttribute("allowfullscreen","allowfullscreen");

	const allow = ele.getAttribute("allow");
	if(allow){
		newEle.setAttribute("allow",allow);
	}

	newEle.setAttribute("src", ele.getAttribute("src") || "");
	let editUrl = '';
	if(String(ele.getAttribute("src")).indexOf("h5p") >= 0){
		newEle.classList.add("h5p-iframe");
		editUrl = getH5PEditUrl(ele.getAttribute("src") || "");
	}
	const hardCodedWidth = ele.getAttribute("width");
	const hardCodedHeight = ele.getAttribute("height");
	if (hardCodedWidth && hardCodedHeight) {
		newEle.setAttribute("hc-width", hardCodedWidth);
		newEle.setAttribute("hc-height", hardCodedHeight);
	}

	calculateAspectRatio(newEle);
	iframeWrapper.append(newEle);
	if(document.querySelector("header a.edit-button") && editUrl){
		const editLink = document.createElement("a");
		editLink.classList.add("h5p-edit-link");
		editLink.setAttribute("href", editUrl);
		editLink.setAttribute("target", "_blank");
		iframeWrapper.append(editLink);
	}
	ele.parentElement?.insertBefore(iframeWrapper,ele);
	ele.classList.add("hidden-embedding-placeholder");
	newEle.contentWindow?.postMessage({context: 'h5p', action: 'ready'}, '*');
	window.dispatchEvent(new Event("embed-allowed"));
	resizeEmbedding(newEle);

	if(ele.style.aspectRatio){
		newEle.style.aspectRatio = ele.style.aspectRatio;
		newEle.style.height = "auto";
	}
};

export const showEmbeddingSections = (container:HTMLElement | null) => {
	if(container === null){return;}
	for(const child of Array.from(container.children)){

		if(child.tagName === "LAZY-IFRAME"){
			showLazyIframe(child);
			continue;
		}
		if(child.tagName !== "SECTION"){continue;}
		if(child.getAttribute("content-type") === "exercise"){
			showEmbeddingSections(child.querySelector("exercise-content"));
		}
		if(child.getAttribute("content-type") !== "embedding"){continue;}
		const iframes = Array.from(child.querySelectorAll<HTMLIFrameElement>("iframe"));
		for(const iframe of iframes){
			iframe.contentWindow?.postMessage({context: 'h5p', action: 'resize'}, '*');
		}
		for(const lazyIframe of Array.from(child.querySelectorAll("lazy-iframe"))){
			showLazyIframe(lazyIframe);
		}
	}
};

const getClientWidth = () => {
	let width = 0;
	if (window.innerWidth > 950) {
		width = 830; //950 - 2*60
	} else {
		width = window.innerWidth - 64;
	}
	return width;
};

const resizeEmbedding = (ele:HTMLIFrameElement) => {
	const arRaw = ele.getAttribute('aspect-ratio');
	const ar = arRaw ? parseFloat(arRaw) : 1.5;
	if (ele.clientWidth === 0) {
		const clientWidth = getClientWidth();
		ele.style.height = `${((clientWidth / ar)|0)+1}px`;
	} else {
		ele.style.height = `${((ele.clientWidth / ar)|0)+1}px`;
	}
};

const resizeEmbeddings = () => {
	const eles:HTMLIFrameElement[] = Array.from(document.querySelectorAll('iframe[aspect-ratio]'));
	for(const e of eles){
		resizeEmbedding(e);
	}
};

export const hideAllEmbeddings = (root: HTMLElement) => {
	root.querySelectorAll('iframe-wrapper')
		.forEach(e => e.remove());
	const lazyIframes = Array.from(root.querySelectorAll<HTMLElement>('.hidden-embedding-placeholder'));
	lazyIframes.forEach(e => e.classList.remove('hidden-embedding-placeholder'));
	return lazyIframes;
};

addHideElementContentHandler("hideEmbeddings", hideAllEmbeddings);

function initLazyIframes(){
	showEmbeddingSections(document.querySelector("main"));
}
setTimeout(initLazyIframes,0);
window.addEventListener('resize', resizeEmbeddings);


//because the resizing should trigger once after all ressources are loaded
window.addEventListener("load", () => {
	setTimeout(() => { window.dispatchEvent(new Event("resize")); }, 0);
});
