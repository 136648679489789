import { showModal } from "../../modal/modal";
import { apiGetCourseNotesForStudents, IDraftNote, INote } from "@digitale-lernwelten/ugm-client-lib";
import { isStudent } from '../user-data';
import configuration from '../../../configuration';
import { createAttachmentNode } from "../notes/notes";
import { DataStore } from "../datastore";
import { getFirstParentSection } from "../../_helper";

import "./course-note.css";

const isDraft = (note:any):boolean => !(note.isPublished || note.body || note.subject);
const getNoteData = (exerciseId:string) => store.find((t:INote | IDraftNote) => t.exerciseId === exerciseId);

const addOrUpdateTeacherNote = (note:INote | IDraftNote) => {
	if(note.deletedAt || isDraft(note)){
		const oldNote = store.get(note.id);
		/* Only remove the note if the id is the same, otherwise and old delted note
		 * will keep us from ever adding another note to that particular exercise
		 */
		if(oldNote && (oldNote.id === note.id)){
			store.delete(note.id);
			refreshAllTeacherNotes();
		}
	} else {
		refreshTeacherNoteMarker(note.exerciseId);
	}
};

const showTeacherNote = async (exerciseId:string) => {
	const raw = getNoteData(exerciseId);
	if(!raw || isDraft(raw)){return;}
	const data = raw as INote;

	const form = document.createElement("note-modal");
	form.innerHTML = `<h3>${data.subject}</h3><div class="teacher-note-body">${data.body}</div>`;

	const wrap = document.createElement("div");
	wrap.classList.add("teacher-note-attachment-wrap");
	form.append(wrap);
	data.files
		.sort((a,b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
		.forEach(f => wrap.append(createAttachmentNode(data, f, false)));

	const tempWrapper = document.createElement("temporary-modal");
	document.body.append(tempWrapper);
	const modalContent = document.createElement("modal-content");
	modalContent.append(form);
	tempWrapper.append(modalContent);
	showModal(tempWrapper);
};

const refreshTeacherNoteMarker = (exerciseId:string) => {
	const marker = document.querySelector(`section[content-type-id="${exerciseId}"] > markers-left`);
	const noteMarker = document.querySelector(`section[content-type-id="${exerciseId}"] course-note-marker`);
	if((!marker) || (!noteMarker)){return;}

	const note = getNoteData(exerciseId);
	if(!note){
		noteMarker.classList.remove("filled");
	}else{
		noteMarker.classList.add("filled");
	}
};

const refreshAllTeacherNotes = () => {
	document.querySelectorAll(`section`).forEach((e) => {
		const exerciseId = e.getAttribute("content-type-id");
		if(!exerciseId){return;}
		refreshTeacherNoteMarker(exerciseId);
	});
};

const initTeacherNoteMarker = (marker:HTMLElement) => {
	const exerciseId = getFirstParentSection(marker)?.getAttribute("content-type-id");
	if(!exerciseId){return;}
	const numberedMarker = marker.querySelector(".marker");
	if(!numberedMarker){return;}
	const noteMarker = document.createElement("COURSE-NOTE-MARKER");
	marker.append(noteMarker);
	noteMarker.addEventListener("click", () => showTeacherNote(exerciseId));
	refreshTeacherNoteMarker(exerciseId);
};

if(configuration.ugmEndpoint){
	setTimeout(() => {
		document.querySelectorAll("markers-left").forEach(initTeacherNoteMarker);
	}, 0);
}

const doPoll = async (after?: Date) => {
	if (!isStudent()) { return []; }
	return await apiGetCourseNotesForStudents(after);
};

const store:DataStore<INote | IDraftNote> = new DataStore("ugm-teacher-note", addOrUpdateTeacherNote, refreshAllTeacherNotes, doPoll);
