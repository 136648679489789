/* This File should contain little helper functions to be used around the codebase */

import {callOverlayCloseHandlers} from "./overlay/overlay";

export const getFirstParentSection = (ele:HTMLElement | null) : HTMLElement | null => {
	if (!ele) { return null; }
	if (ele.tagName === 'SECTION') { return ele; }
	return getFirstParentSection(ele.parentElement);
};

export const getLastParentSection = (ele:HTMLElement | null, lastSection:HTMLElement | null = null) : HTMLElement | null => {
	if (!ele) { return lastSection; }
	return getLastParentSection(ele.parentElement, ((ele.tagName === 'SECTION') ? ele : lastSection));
};

export const fileExtension = (filename:string) => {
	const i = filename.lastIndexOf(".");
	return i < 0 ? "" : filename.substring(i+1).toLowerCase();
};

/* Download some content immediatly */
export const downloadData = (filename:string, data:string) => {
	const a = document.createElement('a');
	a.setAttribute('href', data);
	a.setAttribute('download', filename);
	a.style.display = 'none';

	document.body.appendChild(a);
	a.click();
	document.body.removeChild(a);
};

const hideElementContentHandlerList:Map<string, (ele:Element) => void> = new Map();
/* Use this function to add functions that disable active elements, such as iframes/videos */
export const addHideElementContentHandler = (name:string, handler:(ele:Element) => void) => {
	hideElementContentHandlerList.set(name, handler);
};

/* Should be called on elements that are loosing visiblity or getting deactivated/disabled */
export const hideElementContentHandler = (ele:Element) => {
	hideElementContentHandlerList.forEach(fn => fn(ele));
};

/* Important to do this here and not in the closeFullscreen
 * procedure because a user might exit fullscreen mode by
 * pressing ESC or something similar.
 */
document.addEventListener("fullscreenchange", () => {
	if( window.innerHeight === screen.height){return;}
	document.documentElement.style.height = "";
});

interface VendoredHTMLElementMethods  {
	requestFullscreen: () => void;
	webkitRequestFullscreen: (() => void) | undefined;
}
type VendoredHTMLElement = HTMLElement & VendoredHTMLElementMethods;

interface VendoredDocumentMethods  {
	requestFullscreen: () => void;
	webkitExitFullscreen: (() => void) | undefined ;
}
type VendoredDocument = Document & VendoredDocumentMethods;


/* The document height needs to be set to a fixed value because as soon
 * as we enter fullscreen mode, the element gets removed from the
 * text flow, reducing the overall document height. Now as soon as we
 * return to the normal mode the browsers scroll changes because it is
 * larger than the overall document height. By setting it on entering
 * fullscreen (and removing the value later) we work around that issue.
 */
export const openFullscreen = (element:VendoredHTMLElement | HTMLElement) => {
	document.documentElement.style.height = (document.documentElement.scrollHeight|0)+"px";
	const ele = <VendoredHTMLElement>element;
	if (ele.requestFullscreen) {
		ele.requestFullscreen();
	} else if (ele.webkitRequestFullscreen) { /* Another solution would be nice, but right now this seems like the simplest */
		ele.webkitRequestFullscreen();
	} else {
		return;
	}
};

export const isFullscreen = () => document.fullscreenElement !== null;

export const closeFullscreen = () => {
	callOverlayCloseHandlers();
	const doc = <VendoredDocument>document;
	if (!isFullscreen()) { return; }
	if (document.exitFullscreen) {
		document.exitFullscreen();
	} else if (doc.webkitExitFullscreen) { /* Another solution would be nice, but this seems quite simple and does the trick */
		doc.webkitExitFullscreen();
	} else {
		return;
	}
};

export const scrollToElement = (element:HTMLElement, elementOffset:number) => {
	if (!element) { return;}
	const parentS = getFirstParentSection(element);
	if (!parentS) { return;}
	const eleRectY = parentS.getBoundingClientRect().top;
	const position = eleRectY + window.scrollY - elementOffset;
	window.scrollTo({
		top: position,
		behavior: "smooth"
	});
};

export const stripHref = (url:string):string => {
	if(url.indexOf("?") >= 0){
		return stripHref(url.substring(0, url.indexOf("?")));
	} else if(url.indexOf("#") >= 0){
		return stripHref(url.substring(0, url.indexOf("#")));
	}
	return url;
};

const urlParams = new URLSearchParams(window.location.search);
export const getParam = (name:string) => urlParams.get(name);

export const setCookie = (name:string, value:string) => {
	const cDate = new Date();
	cDate.setDate(cDate.getDate() + (365*5));
	const expires:string = `expires=` + cDate.toUTCString();
	document.cookie = `${name}=${value||""}; ${expires}; path=/`;
	if(cookieMapCache){
		cookieMapCache[name]=value;
	}
};

const mobileAndTabletCheck = () => {
	return window.matchMedia("(pointer: coarse)").matches;
};
export const isMobile = mobileAndTabletCheck();

let cookieMapCache:Record<string, string> | undefined = undefined;
const getCookieMap = () => {
	if(cookieMapCache){
		return cookieMapCache;
	}
	const ret:Record<string, string> = {};
	document.cookie.split(";").forEach(c => {
		const s = c.trim().split("=");
		if(s.length !== 2){
			return;
		}
		ret[s[0].trim()] = s[1].trim();
	});
	if(!cookieMapCache){
		cookieMapCache = ret;
	}
	return ret;
};
export const getCookie = (name:string):string | undefined => getCookieMap()[name];

export const clearCookie = (name:string) => {
	document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
	if(cookieMapCache){
		cookieMapCache[name] = '';
	}
};

export const onEnterDo = (λ:((e:Event) => void)) => {
	return (e : KeyboardEvent) => {
		if(['Enter', 'NumpadEnter'].includes(e.key)) {
			λ(e);
		}
	};
};
