import Configuration from "../../../configuration";
import { addHideElementContentHandler } from "../../_helper";
import { canUseEmbeds} from "../../privacy/privacy";

const decodeParam = (g:string) => decodeURIComponent(g).replace(/\+/g,' ');

const getParamValue = (key:string, href:string) => {
	const params = href ? href.split('?') : window.location.search.split('?');
	if(params.length !== 2){
		return '';
	}
	const split = params[1].split('&');
	for (let i = 0; i < split.length; i++) {
		const item = split[i].split('=');
		if (item[0] === key) { return decodeParam(item[1]); }
		if (item[0] === encodeURIComponent(key)) { return decodeParam(item[1]); }
	}
	return '';
};

const ytCalcStart = (t:string) : number => {
	let ret = 0;
	const m = t.match(/(\d+[a-z])/g);
	if(!m){return 0;}

	for(let i=0;i<m.length;i++){
		const cstr = String(m[i]);
		const unit = cstr.charAt(cstr.length-1).toLowerCase();
		const count = parseInt(cstr.substring(0,cstr.length-1));
		if(unit === 's'){
			ret += count;
		}
		if(unit === 'm'){
			ret += count*60;
		}
		if(unit === 'h'){
			ret += count*3600;
		}
	}
	return ret;
};

const embeddingHandlers:Map<string, (href:string, autoplay:boolean) => (string | null)> = new Map();

embeddingHandlers.set("vimdeo", (href,autoplay) => {
	const m = href.match(/https?:\/\/vimeo.com\/(\d+)/i);
	if(!m){return null;}
	const vid = m[1];
	return `https://player.vimeo.com/video/${vid}${autoplay ? "?autoplay=1" : ""}`;
});

embeddingHandlers.set("youtube", (href,autoplay) => {
	const m = href.match(/https?:\/\/www.youtube.com\/watch?.*/i);
	if(!m){return null;}
	const vid = getParamValue('v',href);
	const params:string[] = [];
	const time = getParamValue('t', href);
	const start = getParamValue('start', href);
	const end = getParamValue('end', href);
	if(autoplay){
		params.push('autoplay=1');
	}
	if(Configuration.ytCaption) {
		params.push('cc_load_policy=1');
		params.push('cc_lang_pref=de');
	}
	if(time){
		params.push('start='+ytCalcStart(time));
	}
	if (start) {
		params.push('start=' + start);
	}
	if (end) {
		params.push('end=' + end);
	}
	return `https://www.youtube-nocookie.com/embed/${vid}${params.length > 0 ? "?"+params.join('&') : ""}`;
});

embeddingHandlers.set("tagesschau", (href, autoplay) => { // eslint-disable-line @typescript-eslint/no-unused-vars
	const m = href.match(/https?:\/\/www.tagesschau.de\/multimedia\/video\/video-(\d+).*/i);
	if(!m){return null;}
	const vid = m[1];
	const params:string[] = [];
	const start = getParamValue('start', href);
	const end = getParamValue('end', href);
	if (start) {
		params.push(`start=${start}.00`);
	}
	if (end) {
		params.push(`ende=${end}.00`);
	}
	return `https://www.tagesschau.de/multimedia/video/video-${vid}~player.html${params.length > 0 ? "?"+params.join('&') : ""}`;
});

embeddingHandlers.set("arte", (href, autoplay) => {
	const m = href.match(/https?:\/\/www.arte.tv\/(de|fr|en|es|pl|it)\/videos\/([^/]*)\/*/); // could be more optimized
	if(!m){return null;}
	const lang = m[1];
	const id   = m[2];
	return `https://www.arte.tv/embeds/${lang}/${id}?autoplay=${autoplay ? 1 : 0}`;
});

const embeddingGetHref = (href:string, autoplay:boolean): (string | null) => {
	let ret: (string | null) = null;
	embeddingHandlers.forEach(v => {
		ret = ret || v(href, autoplay);
	});
	return ret;
};

const textToEmbedding = (href:string, autoplay:boolean) => {
	const embedHref = embeddingGetHref(href,autoplay);
	if(!embedHref){return null;}
	const ret = document.createElement("iframe");
	ret.setAttribute("src", embedHref);
	ret.setAttribute('width',"870");
	ret.setAttribute('height',"490");
	ret.setAttribute('frameborder',"0");
	ret.setAttribute("allow","autoplay; fullscreen");
	return ret;
};

const aspectRatios:Map<string, number> = new Map();
aspectRatios.set("youtube.com", 56.5);
aspectRatios.set("tagesschau.de", 56.25);
aspectRatios.set("vimeo.com", 56.25);

const setThumbnailAspectRatio = (embedLink:HTMLAnchorElement) => {
	if (!embedLink.href) {
		return;
	}
	const thumbnail:HTMLElement | null = embedLink.querySelector(".embedding-video-thumbnail");
	if (thumbnail) {
		// if the aspect ratio is set, skip it since it was already set via config override
		if (thumbnail.style.aspectRatio) {
			return;
		}
		const host = new URL(embedLink.href).hostname.replace(/www./g, "");
		const ar = aspectRatios.get(host) || 56.5;
		thumbnail.style.aspectRatio= `1/${ar/100.0}`;
	}
};

export const initEmbeddingLinks = () => {
	addHideElementContentHandler("hideVideoEmbeddings", ele => {
		ele.querySelectorAll('iframe-wrap[iframe-type="video"]').forEach(e => e.remove());
		ele.querySelectorAll<HTMLElement>(".hidden-video-placeholder").forEach(e => {
			e.classList.remove('hidden-video-placeholder');
		});
	});
	document.querySelectorAll<HTMLAnchorElement>("a.embedding-link").forEach(link => {
		setThumbnailAspectRatio(link);
		link.setAttribute("embedding-target","video");
		const embeddingIframe = textToEmbedding(link.href,true);

		if(!embeddingIframe){return;}
		if (link.style.aspectRatio) {
			embeddingIframe.style.aspectRatio = link.style.aspectRatio;
		}
		link.onclick = async e => {
			e.preventDefault();
			if (await canUseEmbeds()) {
				const wrap = document.createElement("iframe-wrap");
				wrap.setAttribute("iframe-type","video");
				wrap.append(embeddingIframe);
				link.parentElement?.insertBefore(wrap,link);
				embeddingIframe.classList.add("video-iframe");
				link.classList.add("hidden-video-placeholder");
			}
		};
	});
};
setTimeout(initEmbeddingLinks,0);

