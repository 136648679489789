import { TranslationMap, getTranslation } from "../../../common/i18n";
export * from "./languages";

export const getLang = ():string => document.documentElement.lang || "de";

let lastMap:TranslationMap | null = null;
export const t = ():TranslationMap => {
	if(lastMap){
		return lastMap;
	} else {
		lastMap = getTranslation(getLang());
		return lastMap;
	}
};
export default t;
