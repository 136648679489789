import { getParam } from "../_helper";

/* Traverse every node under <main> and replace every occurence of word in Text Nodes with <mark>${mark}</mark> */
export const highlightWord = (word: string, className = "sword-mark", ele?: Element) => {
	if(!ele || (ele.tagName === "MARK")){
		return;
	}
	for(const node of ele.childNodes){
		if(node instanceof Element){
			highlightWord(word, className, node);
		} else if(node instanceof Text){
			let i = 0;
			while((i = node.wholeText.toLowerCase().indexOf(word)) >= 0){
				const sword = node.wholeText.substring(i, i + word.length);
				node.splitText(i + word.length);
				const swordNode = node.splitText(i);
				const mark = document.createElement('mark');
				mark.classList.add(className);
				mark.innerText = sword;
				ele.replaceChild(mark, swordNode);
			}
		}
	}
};

export const initSwordHighlight = () => {
	const sword = getParam("sword");
	if(!sword){return;}
	const keywords = sword.toLowerCase().replace(/\s+/g, ' ').trim().split(' ').filter(w => w.length > 2);
	const main = document.querySelector("main");
	if(!main){
		throw new Error("Can't find <main>");
	}
	keywords.forEach(w => highlightWord(w, "sword-mark", main));

	const firstMark = main.querySelector(`mark.sword-mark`);
	if(firstMark){
		firstMark.scrollIntoView({
			behavior: "instant",
			block: "center",
			inline: "center"
		});
	}
};

setTimeout(initSwordHighlight, 0);
