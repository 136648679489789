import { clearCookie } from "../_helper";
import t from "../i18n";

interface StoredItem {
	key: string,
	value: string | any
}

const getAllLocalStorage = () => {
	//es2015 vodoo
	const localItems = { ...localStorage};
	const allLocalItems=[];
	for (const item in localItems) {
		allLocalItems.push(<StoredItem>{key:item,value:localItems[item]});
	}
	return allLocalItems;
};
//
const getAllCookies = () => {
	const rawCookies = document.cookie.split(";");
	const cookies = rawCookies.map((cookie:any) => (cookie.split("=").map((str:any) => str.trim())));
	return cookies;
};

const parseKeys = (key:string) => {
	if (!key) { return "";}
	switch (key) {
	case "allowExternalEmbeds":
		return t().allowExternalEmbeds;
	case "darkMode":
		return t().darkMode;
	case "page-view-history":
		return t().page_view_history;
	case "LANG":
		return t().LANG;
	case "local_only_ugm-notes":
		return t().local_only_ugm_notes;
	case "user-star-level":
		return t().user_star_level;
	case "star-data":
		return t().star_data;
	case "local_only_ugm-correction":
		return t().star_data;
	case "ugm-notes":
		return "gespeicherte Notizen";
	case "ugm-course-pad":
		return "gespeicherte Etherpads";
	case "user-data":
		return "gespeicherte Nutzerdaten (Loginname,id)";
	case "ugm-auth-cookie":
		return "gespeicherte Sitzungcookie";
	case "ugm-type-cookie":
		return "der Typ des Nutzers";
	case "guid":
		return "Zufallsgenerierte NutzerID";
	default:
		return "";
	}
};

const optOutElement = (storedItem:StoredItem) =>{
	if (!storedItem) { return "";}
	if (!storedItem.key || !storedItem.value) { return"";}
	const optOutItem = document.createElement("optout-item");
	const storeValue = JSON.parse(storedItem.value);
	if (!storeValue) {return "";}
	const parsedKey = parseKeys(storedItem.key);
	optOutItem.innerHTML = `<optout-key>${parsedKey}</optout-key>`;
	return optOutItem;
};

const storageItemRemoveBtn = (btn:HTMLElement) => {
	if (!btn) { return;}
	if (!btn.dataset) { return;}
	btn.addEventListener("click", (e:Event) => {
		if (!e.target) {return;}
		const ele = e.target as HTMLElement;
		if (!ele.dataset) { return;}
		const name = ele.dataset.key as string | "";
		if (name === "user-star-level") {
			const localItems = getAllLocalStorage();
			for (const item of localItems) {
				if (item.key.startsWith("user-star-level")) {
					localStorage.removeItem(item.key);
				}
			}
		} else {
			clearCookie(name);
			localStorage.removeItem(name);
		}
		initOptOut();
	});
};

const initOptOut = () => {
	const privacy = document.querySelector<HTMLElement>(`main[page-type="privacy"] privacy-settings`);
	if (!privacy) { return;}
	const localStorage = getAllLocalStorage();
	const cookies = getAllCookies();
	let storedStar = false;

	const optOutContainer = privacy.querySelector<HTMLElement>("opt-out-container");
	if (!optOutContainer) { return;}
	const allChildElements = Array.from(optOutContainer.children);
	for (const element of allChildElements) { element.remove();}
	localStorage.forEach((storedItem:StoredItem) => {
		let optOutItem:HTMLElement;
		const optOutBtn = document.createElement("optout-remove");
		optOutBtn.textContent = t().delete;
		if (storedItem.key.startsWith("user-star-level")) {
			if (storedStar) { return;}
			storedStar = true;
			optOutItem = <HTMLElement>optOutElement({key:"user-star-level", value:true});
			optOutBtn.dataset.key = "user-star-level";
		} else {
			optOutItem = <HTMLElement>optOutElement(storedItem);
			optOutBtn.dataset.key = storedItem.key;
		}
		storageItemRemoveBtn(optOutBtn);
		optOutItem.append(optOutBtn);
		optOutContainer.append(optOutItem);
	});
	cookies.forEach(item => {
		if (item.length!== 2) { return;}
		if (item[1] === "de") { return;}
		const optOutItem = optOutElement({key:item[0], value:JSON.stringify(item[1])}) as HTMLElement;
		const optOutBtn = document.createElement("optout-remove");
		optOutBtn.textContent = t().delete;
		optOutBtn.dataset.key = item[0];
		storageItemRemoveBtn(optOutBtn);
		optOutItem.append(optOutBtn);
		optOutContainer.append(optOutItem);
	});
};
setTimeout(initOptOut, 0);
