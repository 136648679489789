import {
	apiActivateStudent,
	apiVerifyStudentActivation,
	apiResetStudentPassword,
	apiVerifyStudentPasswordReset,
	IStudent
} from "@digitale-lernwelten/ugm-client-lib";
import { showModal } from "../../modal/modal";
import { hideOverlay } from "../../overlay/overlay";
import configuration from "../../../configuration";
import { ugmLogin } from "../user-group-management";
import t from "../../i18n";

import "./user-activation.css";

type TLinkType = 'activation' | 'password'

const validatePassword = (pw:string):boolean => pw.length >= 8;

const showErrorModal = () => {
	const modal = showModal(`
	<div class="login-modal">
		<h3>${t().activationFailedHeader}</h3>
		<br/>
		<p>${t().activationFailedText}</p>
	</div>`,
	"centered-box");
	const button = modal.querySelector<HTMLInputElement>(`input[type="submit"]`);
	if(!button){return;}
	button.onclick = hideOverlay;
};

const showSuccesModal = (username:string) => {
	const modal = showModal(`
	<div class="success-modal">
		<h3>${t().activationSuccessHeader}</h3>
		<h5>${username}</h5>
		<br/>
		<p>${t().activationSuccessText}</p>
		<input type="submit" value="Los geht's!"/>
	</div>`,
	"centered-box");
	const button = modal.querySelector<HTMLInputElement>(`input[type="submit"]`);
	if(!button){return;}
	button.onclick = hideOverlay;
};

const verifyToken = async(token: string, linkType: TLinkType): Promise<boolean> => {
	if (linkType === 'activation') {
		return apiVerifyStudentActivation(token);
	} else {
		return apiVerifyStudentPasswordReset(token);
	}
};

const initUserActivationModal = async (token: string, linkType: TLinkType) => {
	try {
		const tokenValidation = await verifyToken(token, linkType);
		if(!tokenValidation){
			showErrorModal();
			return;
		}
	} catch {
		showErrorModal();
		return;
	}
	const linkPrivacy = `<a href="/datenschutz" target="_blank">${t().privacyPolicy}</a>`;
	const modal = showModal(`
	<div class="login-modal">
		<form>
			<h3>${ linkType === 'activation' ? t().activationAccess : t().passwordReset}</h3>
			<h4>${window.location.hostname}</h4>
			<label>
				<span>${t().password}
					<details class="hint">
						<summary title="Info">i</summary>
						<div>${t().passwordInfo}</div>
					</details>
				</span>
				<span class="errors"></span>
				<input tabindex="1" type="password" name="password" value="" placeholder="${t().passwordEnter}" autocomplete="off"/>
			</label>
			<label>
				<span class="error-pw-response">${t().passwordRepeat}
				</span>
				<span class="repeat-errors"></span>
				<input tabindex="2" type="password" name="repeat-password" value="" placeholder="${t().passwordRepeat}" autocomplete="off"/>
			</label>
			<label class="check-container">
				${t().acceptAgb(linkPrivacy)}
				<input type="checkbox" name="check-agree"/>
				<span class="checkmark"></span>
			</label>
			<input type="submit" class="inactive" value="${t().activationSubmit}"/>
		</form>
	</div>`,
	"centered-box");
	const button = modal.querySelector<HTMLInputElement>(`input[type="submit"]`);
	const password = modal.querySelector<HTMLInputElement>(`input[type="password"]`);
	const errormsg = modal.querySelector<HTMLElement>(".errors");
	const passwordRepeat = modal.querySelector<HTMLInputElement>(`input[name="repeat-password"]`);
	const errormsgRepeat = modal.querySelector<HTMLElement>(".repeat-errors");
	const errorEleResponse = modal.querySelector<HTMLElement>(".error-pw-response");
	const checkAgb = modal.querySelector<HTMLInputElement>(`[name="check-agree"]`);
	if (!button || !password || !errormsg || !passwordRepeat || !errormsgRepeat || !errorEleResponse || !checkAgb ) { return;}
	password.focus();
	password.addEventListener("keyup", () => {
		if (validatePassword(password.value)) {
			errormsg.innerText = "";
		} else {
			errormsg.innerText = t().passwordError;
			button.classList.add("inactive");
		}
		checkAgb.dispatchEvent(new Event("change"));
	});
	passwordRepeat.addEventListener("keyup", () => {
		if (password.value === passwordRepeat.value && validatePassword(password.value)) {
			errormsgRepeat.innerText = "";
			errorEleResponse.classList.add("repeat-ok");
		} else {
			errormsgRepeat.innerText = t().passwordDiffers;
			button.classList.add("inactive");
			errorEleResponse.classList.remove("repeat-ok");
		}
		checkAgb.dispatchEvent(new Event("change"));
	});
	checkAgb.addEventListener("change", ()=>{
		if (password.value === passwordRepeat.value
			&& validatePassword(password.value)
			&& checkAgb.checked) {
			button.classList.remove("inactive");
		} else {
			button.classList.add("inactive");
		}
	});
	button.onclick = async e => {
		e.preventDefault();
		if(button.classList.contains("inactive")|| !checkAgb.checked || password.value !== passwordRepeat.value){return;}
		if (!validatePassword(password.value)){return;}
		try {
			password.readOnly = true;
			button.classList.add("inactive"); // This is to make sure that we don't double submit
			let currentUser: IStudent;
			if (linkType === 'activation') {
				currentUser = await apiActivateStudent(token, password.value);
			} else {
				currentUser = await apiResetStudentPassword(token, password.value);
			}
			if(currentUser){
				errormsg.innerText = '';
				await ugmLogin(currentUser.loginName, password.value);
				hideOverlay();
				const newUrl = window.location.href.substring(0,window.location.href.indexOf("?"));
				window.history.replaceState(null, "", newUrl);
				showSuccesModal(currentUser.loginName);
			}else{
				errormsg.innerText = `${t().activationError}<br/><br/>`;
			}
		}catch(e) {
			password.readOnly = false;
			button.classList.remove("inactive");
			errormsg.innerHTML = `${t().activationError}<br/><br/>`;
		}
	};
};

const initUGMUserActivation = () => {
	const urlParams = new URLSearchParams(window.location.search);
	const token = urlParams.get("ugm-activate") || urlParams.get("ugm-pw-reset");
	let linkType: TLinkType | null = null;
	const act = urlParams.get("ugm-activate");
	const pw = urlParams.get("ugm-pw-reset");
	if (act) {
		linkType = 'activation';
	} else if (pw) {
		linkType = 'password';
	}

	if (token && linkType){
		initUserActivationModal(token, linkType);
	}
};

if(configuration.ugmEndpoint){
	setTimeout(initUGMUserActivation,0);
}
