import Configuration from "../../../configuration";
import { getCurrentPageId, readStarLevel, changeDefaultStarLevel } from "./star-selection";

import "./star-selection-user-level.css";

const setStarLevel = (stars:Element [],level:string ) => () => {
	const currentPageID = getCurrentPageId();
	localStorage.setItem(`user-star-level-${currentPageID}`, level);
	stars.forEach((star, i) => {
		star.classList.remove("active");
		if (i < parseInt(level)) {
			star.classList.add("active");
			star.classList.add("star-highlight");
		}
		setTimeout(() => {
			star.classList.remove("star-highlight");
		}, 300);
	});
	changeDefaultStarLevel(level);
};

const initstarSelectionUserLevelUI = () => {
	const starSelectionUserLevel = document.querySelector<HTMLElement>("star-selection-user-level");
	if (!starSelectionUserLevel) { return;}
	const starContainer = starSelectionUserLevel.querySelector<HTMLElement>(".user-star-selection-container");
	if(starContainer){
		const stars = Array.from(starContainer.children);
		stars.forEach((star, i) => {
			if (i < parseInt(readStarLevel())) {
				star.classList.add("active");
			}
			star.addEventListener("click", setStarLevel(stars, (i+1).toString()));
		});
	}
};

if(Configuration.enableStarSelectionUserLevel){
	setTimeout(initstarSelectionUserLevelUI,0);
}

