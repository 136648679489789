import type { IndexEntryJSON } from '../../../common/types';
import configuration from '../../configuration';

export * from './swordHighlight';
import "./search.css";

export interface IndexEntry {
	id: string;
	url: string;
	language: string;
	contentType: string;
	subType: string;
	categories: Set<string>;
	teaser: string;
	relevance: number;
	navEntry: HTMLElement | null;
	resultWrap: HTMLElement | null;
	link: HTMLAnchorElement | null;
	keywords: Record<string, number>;
	parent?: IndexEntry;
	children: IndexEntry[];
}

const contentTypeRelevanceMultiplier:Record<string, number> = {
	"Glossary": 30,
	"Operators": 30,
	"Content": 150,
	"Headline.h1": 150,
	"Headline.h2": 80,
	"Headline.h3": 30,
	"Headline.h4": 10,
	"Headline": 6,
	"TextAndImage": 3,
	"HeroImage": 2
};

let searchIndex: Map<string, IndexEntry> | null = null;

let indexFetching = false;
let currentSword = '';
let lastSword = '';

const transformIndex = (entry: IndexEntryJSON, parent?:IndexEntry): IndexEntry => {
	const navEntry = document.querySelector<HTMLElement>(`li[page-id="${entry.id}"]`);
	const resultWrap = navEntry && navEntry.querySelector<HTMLElement>(":scope > .nav-link-wrap > .search-result-wrap");
	const link = navEntry && navEntry.querySelector<HTMLAnchorElement>(":scope > .nav-link-wrap > a");
	const ret:IndexEntry = {
		id: entry.id,
		url: entry.url,
		language: entry.language,
		contentType: entry.contentType,
		subType: entry.subType,
		categories: new Set(entry.categories),
		teaser: entry.teaser,
		navEntry,
		resultWrap,
		link,
		keywords: entry.keywords,
		relevance: 0,
		parent,
		children: []
	};
	if(resultWrap && link){
		resultWrap.onclick = () => link.click();
	}
	ret.children = entry.children.map(c => transformIndex(c, ret));
	ret.teaser = entry.teaser + ' ' + entry.children.map(c => c.teaser).join(' ');
	return ret;
};

const fetchIndex = async () => {
	if(indexFetching){return;}
	indexFetching = true;
	const raw:IndexEntryJSON[] = await (await fetch("/search-index.json")).json();
	searchIndex = new Map();
	for(const entry of raw){
		const e = transformIndex(entry);
		searchIndex.set(e.id, e);
	}
	setTimeout(updateSearch, 0);
};

const countKeywordOccurence = (entryKeywords: Record<string, number>, searchKeywords: string[]): number => {
	return searchKeywords.reduce((acc, kw) => {
		let cur = 0;
		for(const e in entryKeywords){
			cur += (e.indexOf(kw) >= 0) ? entryKeywords[e] : 0;
		}
		return acc + cur;
	}, 0);
};

const calculateRelevance = (e: IndexEntry, searchKeywords: string[]): number => {
	const childRelevance = e.children.reduce((acc, e) => acc + calculateRelevance(e, searchKeywords), 0);
	const multiplier = contentTypeRelevanceMultiplier[`${e.contentType}.${e.subType}`] || contentTypeRelevanceMultiplier[e.contentType] || 1;
	e.relevance = Math.min(2,countKeywordOccurence(e.keywords, searchKeywords)) * multiplier + childRelevance;
	return e.relevance;
};

const highlightTeaser = (teaser:string, keywords: string[]):string => {
	let passage = '';
	for(const kw of keywords){
		if(passage){continue;}
		const i = teaser.toLowerCase().indexOf(kw);
		if(i >= 0){
			let start = i - 80;
			let end = i + 80;
			while((start > 0) && teaser.charAt(start) !== ' '){start--;}
			while((end < teaser.length) && teaser.charAt(end) !== ' '){end++;}
			start = Math.max(0, start);
			end = Math.min(teaser.length, end);
			passage = (start > 0 ? '…' : '') + teaser.substring(start,end).trim() + (end < (teaser.length-1) ? '…' : '');
		}
	}
	for(const kw of keywords){
		passage = passage.replace(new RegExp('('+kw+')', 'gi'), `<mark>$1</mark>`);
	}
	return passage;
};

const updateSearch = async () => {
	await fetchIndex();
	if(!searchIndex){ return; }

	if(currentSword === lastSword){ return; }
	const sword = (currentSword || '').toLowerCase().replace(/\s+/g, ' ').trim();
	lastSword = currentSword;
	if(!sword){
		for(const entry of searchIndex.values()){
			if(entry.navEntry){
				entry.navEntry.removeAttribute('search-relevance');
			}
			if(entry.resultWrap){
				entry.resultWrap.style.maxHeight = '0';
			}
		}
		return;
	}

	const keywords = sword.split(' ').filter(w => w.length > 2);
	Array.from(searchIndex.values()).forEach(e => e.navEntry && calculateRelevance(e, keywords));
	const maxRelevance = Math.max(1,Array.from(searchIndex.values()).reduce((acc, e) => Math.max(acc, e.relevance), 0));
	for(const entry of searchIndex.values()){
		if(entry.navEntry){
			entry.navEntry.setAttribute('search-relevance', String(Math.ceil((entry.relevance / maxRelevance) * 4)));
			//entry.navEntry.setAttribute('search-relevance-raw', String(entry.relevance));
			if(entry.resultWrap) {
				if(entry.relevance > 0){
					entry.resultWrap.innerHTML = highlightTeaser(entry.teaser, keywords);
					entry.resultWrap.style.maxHeight = '7em';
				} else {
					entry.resultWrap.style.maxHeight = '0';
				}
			}
		}
	}
};

const initSearchInput = (input: HTMLInputElement) => {
	input.onkeyup = () => {
		currentSword = input.value;
		updateSearch();
	};
};

const initSearchNavLink = (a: HTMLAnchorElement) => {
	a.addEventListener('click', () => {
		if(currentSword){
			if(a.href.indexOf("sword=") < 0){
				if(a.href.indexOf("?") < 0){
					a.href = a.href + `?sword=${encodeURIComponent(currentSword)}`;
				} else {
					a.href = a.href + `&sword=${encodeURIComponent(currentSword)}`;
				}
			}
		}
	});
};

const initSearch = async () => {
	if(!configuration.enableSearch){
		return;
	}
	document.querySelectorAll(`nav[role="navigation"] a`).forEach(initSearchNavLink);
	document.querySelectorAll(`input[name="sword"]`).forEach(initSearchInput);
};

setTimeout(initSearch, 0);
