import { hideElementContentHandler } from "../../_helper";
import { showEmbeddingSections } from "../embedding/embedding";

import "./scaffolding.css";

export class Scaffolding {
	static map:Map<HTMLElement, Scaffolding> = new Map();
	box: HTMLElement;
	helpUp: HTMLElement;
	helpDown: HTMLElement;
	innerContent: HTMLElement;
	tabs: ScaffoldingTab[];
	currentTab: number;

	static get(box: HTMLElement) {
		const ele = this.map.get(box);
		if(ele){
			return ele;
		} else {
			return new Scaffolding(box);
		}
	}

	closeAll() {
		this.tabs.forEach(t => t.close());
	}

	setCurrentTab(currentTab:number) {
		this.currentTab = currentTab;
		this.closeAll();
		this.refreshHelpText();
	}

	refreshHelpText() {
		const tab = this.tabs[this.currentTab] as ScaffoldingTab | undefined;
		const userHelp = tab?.userHelp || 0;
		const tabCount = tab?.count() || 0;

		if (userHelp >= 0) {
			this.helpUp.classList.add("showMoreHelp");
		} else {
			this.helpUp.classList.remove("showMoreHelp");
		}

		if (userHelp > 0) {
			this.helpDown.classList.add("show");
		} else {
			this.helpDown.classList.remove("show");
		}

		if (userHelp < (tabCount - 1)) {
			this.helpUp.classList.add("show");
		} else {
			this.helpUp.classList.remove("show");
		}
	}

	constructor(box: HTMLElement) {
		this.box = box;

		const innerContent = box.querySelector<HTMLElement>("inner-content");
		if(!innerContent){
			throw new Error("Couldn't find <inner-content>");
		}
		this.innerContent = innerContent;

		const btnContainer = document.createElement("btn-container");
		this.innerContent.append(btnContainer);

		this.helpUp = document.createElement("help-up");
		this.helpUp.innerText = "Hilfe";
		this.helpUp.classList.add("help-btn");
		this.helpUp.onclick = this.nextLevel.bind(this);
		btnContainer.append(this.helpUp);

		this.helpDown = document.createElement("help-down");
		this.helpDown.innerText = "vorherige Hilfe";
		this.helpDown.classList.add("help-btn");
		this.helpDown.onclick = this.prevLevel.bind(this);
		btnContainer.append(this.helpDown);

		this.tabs = Array.from(box.querySelectorAll<HTMLElement>("tab-box-content"))
			.map((tab, tabIndex) => new ScaffoldingTab(this, tab, tabIndex));
		Scaffolding.map.set(box, this);
	}

	nextLevel() {
		this.tabs[this.currentTab].next();
	}

	prevLevel() {
		this.tabs[this.currentTab].prev();
	}
}

export class ScaffoldingTab {
	parent: Scaffolding;
	tabIndex:number;
	tab: HTMLElement;
	scaffoldingLevels: HTMLElement[] = [];
	userHelp = -1;
	userMaxHelp = -1;

	count() {
		return this.scaffoldingLevels.length;
	}

	addScaffolding(section:HTMLElement){
		const level = parseInt(section.getAttribute("scaffolding") || "0");
		if(!level){return;}
		let wrap = this.scaffoldingLevels[level - 1];
		if(!wrap){
			wrap = this.scaffoldingLevels[level - 1] = document.createElement("help-panel");
			wrap.setAttribute("scaffolding-level", String(level));
			wrap.setAttribute("scaffolding-for-tab", String(this.tabIndex));
			const contentType = section.getAttribute("content-type");
			if(contentType){
				wrap.setAttribute("scaffolding-content-type", contentType);
			}

			const closeBtn = document.createElement("close-help");
			closeBtn.onclick = this.reset.bind(this);
			wrap.prepend(closeBtn);

			this.parent.box.append(wrap);
		}
		wrap.append(section);
	}

	checkScaffoldingForHoles() {
		for(let i=0;i<this.count();i++){
			if(this.scaffoldingLevels[i]){
				continue;
			}
			const section = document.createElement("section");
			section.setAttribute("scaffolding", String(i+1));
			section.setAttribute("content-type", "error");
			section.innerHTML = `<inner-content><h6 class="title">Missing scaffolding level</h6><div class="type">scaffolding</div><p class="message">This exercise is missing a scaffolding for level ${i+1}, please add this one, or remove subsequent levels.</p></inner-content>`;
			this.addScaffolding(section);
		}
	}

	constructor(parent:Scaffolding, tab:HTMLElement, tabIndex: number) {
		this.parent = parent;
		this.tabIndex = tabIndex;
		this.tab = tab;

		for(const section of Array.from(tab.querySelectorAll<HTMLElement>("section[scaffolding]"))){
			this.addScaffolding(section);
		}
		this.checkScaffoldingForHoles();
	}

	switchToHelpIndex(i:number) {
		const newUserHelp = Math.max(-1, Math.min(i, this.count() - 1));
		if(this.userHelp === newUserHelp){return;}
		this.userHelp = newUserHelp;
		this.userMaxHelp = Math.max(newUserHelp, this.userMaxHelp);

		this.close();
		if(this.scaffoldingLevels[this.userHelp]){
			this.scaffoldingLevels[this.userHelp].style.zIndex = String(10);
			this.scaffoldingLevels[this.userHelp].classList.add('show');
			showEmbeddingSections(this.scaffoldingLevels[this.userHelp]);
		}
		this.parent.refreshHelpText();
	}

	close() {
		for(const level of this.scaffoldingLevels){
			if(level.classList.contains("show")){
				hideElementContentHandler(level);
			}
			level.classList.remove('show');
			level.style.zIndex = "";
		}
		this.parent.refreshHelpText();
	}

	reset() {
		this.userHelp = -1;
		this.close();
	}

	next() {
		this.switchToHelpIndex(this.userHelp+1);
	}

	prev() {
		this.switchToHelpIndex(this.userHelp-1);
	}
}
