export const scrollToElementOrParent = (ele: HTMLElement | null) => {
	if(!ele){
		return;
	}
	if((ele.offsetWidth * ele.offsetHeight) === 0){
		scrollToElementOrParent(ele.parentElement);
	} else {
		ele.scrollIntoView({ behavior: "instant", block: "center", inline: "center" });
	}
};

const urlParamsLinks = () => {
	const queryString:string = window.location.search;
	const urlParams:URLSearchParams = new URLSearchParams(queryString);
	if (!urlParams) { return;}
	const ctId = urlParams.get("content-type-id");
	if (!ctId) { return;}
	const querySection = document.querySelector<HTMLElement>(`section[content-type-id="${ctId}"]`);
	scrollToElementOrParent(querySection);
};

setTimeout(() => {
	urlParamsLinks();
},0);
