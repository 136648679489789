import { hideOverlay } from "../overlay/overlay";
import { showModal } from "../modal/modal";
import Configuration from "../../configuration";
import t from "../i18n";

import "./privacy.css";

export const allowUseOfEmbeds = async (v: boolean) => {
	if(v){
		localStorage.setItem('allowExternalEmbeds', 'true');
		continuationsWaitingForAllowedEmbeds.forEach(c => c(null));
	}else {
		localStorage.removeItem('allowExternalEmbeds');
	}
};

export const embedsAllowed = () => {
	if (localStorage.getItem('allowExternalEmbeds') === 'true') {
		return true;
	}
	if (Configuration.overridePrivacy) {
		return true;
	}
	return false;
};

const continuationsWaitingForAllowedEmbeds:Array<(value: unknown) => void> = [];
export const continueWhenAllowed = () => new Promise(resolve => {
	continuationsWaitingForAllowedEmbeds.push(resolve);
});

export const canUseEmbeds = () => new Promise(resolve => {
	if(embedsAllowed()){ resolve(true); return; }
	hideOverlay();

	const modal = showModal(`${t().externalEmbedModal}
		<div id="privacy-btn-wrap">
		<button button-type="accept">${t().accept}</button>
		<button button-type="cancel">${t().cancel}</button>
		</div>`);
	const cancelButton:HTMLElement | null = modal.querySelector(`button[button-type="cancel"]`);
	cancelButton?.addEventListener("click", () => {
		hideOverlay();
		return resolve(false);
	});
	const acceptButton:HTMLElement | null = modal.querySelector(`button[button-type="accept"]`);
	acceptButton?.addEventListener("click", async () => {
		await allowUseOfEmbeds(true);
		hideOverlay();
		return resolve(true);
	});
	acceptButton?.focus();
});
