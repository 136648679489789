import { KvGet, KvSet } from "../user-kv";

const stripUrlProtocol = (url:string) => url.replace("https://", "").replace("http://", "");

window.addEventListener('message', async event => {
	const data = event.data;
	if(!data){
		return;
	}

	const T = data.T;
	if(!T){
		return;
	}
	const origin = stripUrlProtocol(event.origin);

	switch(T){
	case "GetUserData":
		if(data.id){
			const id = `h5p-persist-${origin}-${data.id}`;
			const msg = {
				T: data.T,
				id: data.id,
				data: KvGet(id) || undefined
			};
			const proxy:WindowProxy = event.source as WindowProxy;
			if(proxy && proxy.postMessage){
				proxy.postMessage(msg, event.origin);
			}
		}
		break;
	case "SaveUserData":
		if(data.id){
			const id = `h5p-persist-${origin}-${data.id}`;
			KvSet(id, data.data);
		}
		break;
	default:
		break;
	}
}, false);
