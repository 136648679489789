import { hideElementContentHandler } from "../../_helper";
import { showEmbeddingSections } from "../embedding/embedding";
import { Scaffolding } from "./scaffolding";

import "./working-tasks.css";

export const initWorkingTaskSlider = (box: HTMLElement) => {
	const innerContent = box.querySelector<HTMLElement>("inner-content");
	if(!innerContent){return;}
	let curTaskIndex = -1; // Starts with -1 so that we don't skip the activation of the first task
	const tasks = Array.from(innerContent.querySelectorAll<HTMLElement>("tab-box-content"));

	const prev = document.createElement("button");
	prev.classList.add("working-task-button");
	prev.classList.add("working-task-prev");
	innerContent.append(prev);

	const next = document.createElement("button");
	next.classList.add("working-task-button");
	next.classList.add("working-task-next");
	innerContent.append(next);

	const completionScreen = document.createElement("working-task-completion-screen");
	completionScreen.innerHTML = "<h3>Super, du hast alle Aufgaben bearbeitet!</h3>"; // Placeholder
	innerContent.append(completionScreen);

	const scaffolding = Scaffolding.get(box);

	const refreshButtonVisibility = () => {
		if(curTaskIndex <= 0){
			prev.classList.add("hidden");
		} else {
			prev.classList.remove("hidden");
		}
		if(curTaskIndex >= tasks.length){
			next.classList.add("hidden");
		} else {
			next.classList.remove("hidden");
		}
	};

	const hideActiveTasks = () => {
		for(const oldTask of tasks){
			if(!oldTask.classList.contains("active")){
				continue;
			}
			hideElementContentHandler(oldTask);
			oldTask.classList.remove("active");
		}
	};

	const showCompletionScreen = () => {
		hideActiveTasks();
		completionScreen.classList.add("active");
	};

	const hideCompletionScreen = () => {
		completionScreen.classList.remove("active");
	};

	const switchToTaskIndex = (i:number) => {
		const index = Math.min(tasks.length, Math.max(0, i)); // 0 > i >= tasks.length
		if(index === curTaskIndex){
			return; // Skip switching to the same task so we don't hide and show the iframe loosing all state
		}
		hideActiveTasks();
		if (index === tasks.length) {
			showCompletionScreen();
		} else {
			hideCompletionScreen();

			const task = tasks[index];
			showEmbeddingSections(task);
			task.classList.add("active");
		}
		curTaskIndex = index;
		scaffolding.setCurrentTab(index);
		refreshButtonVisibility();
	};

	prev.onclick = () => switchToTaskIndex(curTaskIndex - 1);
	next.onclick = () => switchToTaskIndex(curTaskIndex + 1);

	switchToTaskIndex(0); // In the future this should be set based on how many tasks a student has completed
};
