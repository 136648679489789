import Configuration from "../../../configuration";
import { getFirstParentSection } from "../../_helper";
import { showModal } from "../../modal/modal";
import { downloadData, fileExtension } from "../../_helper";
import t from "../../i18n";

import "./exercise-files.css";

interface ExerciseFile {
	name: string,
	content?: string,
	type: string
}

interface ExerciseFiles {
	uid: number,
	files: ExerciseFile[]
}

interface PhotoSwipeItem {
	src: string,
	msrc: string | null,
	title: string | null,
	w: number,
	h: number,
	figure?: HTMLElement,
	figcaption?: HTMLElement,
	imgTag?: HTMLImageElement,
	start?: boolean
}

declare const PhotoSwipeUI_Default: any;
declare const PhotoSwipe: {
	init: () => void,
	listen: (eventType:string, cb:() => void) => void,
	getCurrentIndex: () => number,
	new(pswpElement:HTMLElement, ui:any, items:PhotoSwipeItem[], options:any): typeof PhotoSwipe
};


const showInLightbox = (single:HTMLElement, title:string) => {
	const pswpElement = document.getElementById("pswp");
	if (!pswpElement) { return; }
	const imgTag = single.querySelector<HTMLImageElement>("img");
	if (!imgTag) { return; }
	const src    = imgTag.getAttribute("src");
	if (!src) { return; }
	const w      = imgTag.naturalWidth|0;
	const h      = imgTag.naturalHeight|0;
	const msrc   = null;
	const items:PhotoSwipeItem[]  = [{src, msrc, w, h, title}];

	const options = {
		index:0,
		bgOpacity: Configuration.galleryBackgroundOpacity,
		closeOnScroll: false,
		shareEl: false,
		getThumbBoundsFn:()=>{
			const rect = imgTag.getBoundingClientRect();
			const ret = {
				x: rect.x|0,
				y:(rect.y|0) + (document.children[0].scrollTop),
				w:rect.width|0
			};
			return ret;
		}
	};
	const gal = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, items, options);
	gal.init();
};

const initExerciseFiles = () => {
	const wrappers:HTMLElement[] = Array.from(document.querySelectorAll("exercise-files"));
	for (const wrapper of wrappers) {
		const uid = 'exercise_files_' + (getFirstParentSection(wrapper)?.getAttribute("content-type-id") || "unknown");
		const fileList = document.createElement("EXERCISE-FILE-LIST");
		wrapper.appendChild(fileList);

		const fileRenderImage = (obj:ExerciseFile, i:number) => {
			return `<exercise-file file-type="image" file-index="${i}">
				<exercise-file-image><img src="${obj.content}"/></exercise-file-image>
				<exercise-file-name>${obj.name}</exercise-file-name>
				<exercise-file-delete>${t().delete}</exercise-file-delete>
			</exercise-file>`;
		};

		const fileRenderMisc = (obj:ExerciseFile, i:number) => {
			return `<exercise-file file-type="misc" file-index="${i}">
				<exercise-file-placeholder>${fileExtension(obj.name)}</exercise-file-placeholder>
				<exercise-file-name>${obj.name}</exercise-file-name>
				<exercise-file-delete>${t().delete}</exercise-file-delete>
			</exercise-file>`;
		};

		const fileDownloadIndex = async (i:number) => {
			const file = await fileGet(i);
			if((!file) || (!file.content)){return;}
			downloadData(file.name, file.content);
		};

		const fileDeleteIndex = async (i:number) => {
			const data = await fileGetAll();
			data.files = data.files.filter((_:any, fi:number) => fi !== i);
			await fileSetAll(data);
			fileRenderAll(data);
		};

		const fileRender = (obj:ExerciseFile, i:number) => {
			const mimeSplit = obj.type.split("/");
			switch(mimeSplit[0]){
			default:
				return fileRenderMisc(obj,i);
			case "image":
				return fileRenderImage(obj,i);
			}
		};

		const fileSetAll = async (newData:ExerciseFiles) => {
			localStorage.setItem(uid,JSON.stringify(newData));
		};

		const fileGetAll = async () : Promise<ExerciseFiles> => {
			const lsraw = localStorage.getItem(uid);
			return lsraw ? JSON.parse(lsraw) : {uid, files:[]};
		};

		const fileGet = async (i:number): Promise<ExerciseFile | null> => {
			const data = await fileGetAll();
			if((i < 0) || (i > data.files.length)){return null;}
			return data.files[i];
		};

		const fileRenderAll = async (data:ExerciseFiles) => {
			const fileData = data || await fileGetAll();
			fileList.innerHTML = fileData.files.map(fileRender).join("");
			const images:HTMLImageElement[] = Array.from(fileList.querySelectorAll("exercise-file-image"));
			for(const img of images){
				img.addEventListener("click",async () => {
					const i = parseInt(img.parentElement?.getAttribute("file-index") || "0");
					const obj = await fileGet(i);
					showInLightbox(img, obj?.name || "");
				});
			}
			for(const but of Array.from(fileList.querySelectorAll("exercise-file-delete"))){
				const i = parseInt(but.parentElement?.getAttribute("file-index") || "0");
				but.addEventListener("click",() => fileDeleteIndex(i));
			}
			for(const but of Array.from(fileList.querySelectorAll("exercise-file-name,exercise-file-placeholder"))){
				const i = parseInt(but.parentElement?.getAttribute("file-index") || "0");
				but.addEventListener("click",() => fileDownloadIndex(i));
			}
			fileList.setAttribute("max-cols",String(fileData.files.length > 2 ? 2 : 1));
		};

		const fileLoad = (file:File) : Promise<ExerciseFile | null> => new Promise((resolve,reject) => {
			const obj:ExerciseFile = {
				name: file.name,
				type: file.type
			};
			const reader = new FileReader();
			reader.addEventListener("load", () => {
				obj.content = String(reader.result);
				resolve(obj);
			}, false);
			reader.addEventListener("error", e => reject(e));
			reader.addEventListener("abort", e => reject(e));
			reader.readAsDataURL(file);
		});

		const fileSave = async (obj:ExerciseFile) => {
			try {
				const data = await fileGetAll();
				data.files.push(obj);
				await fileSetAll(data);
				fileRenderAll(data);
			} catch(e) {
				showModal(`<h3>Fehler</h3><p>${t().uploadError}</p>`);
			}
		};

		const fileUpload = async (file:File) => {
			const obj = await fileLoad(file);
			if(obj){
				fileSave(obj);
			}
		};

		const fileInput = document.createElement("input");
		fileInput.setAttribute("type", "file");
		fileInput.setAttribute("multiple", "multiple");
		fileInput.addEventListener("change", () => {
			if(fileInput.files){
				Array.from(fileInput.files).forEach(fileUpload);
			}
		});
		wrapper.appendChild(fileInput);

		const uploadButton = document.createElement("EXERCISE-UPLOAD-BUTTON");
		uploadButton.innerText = t().upload;
		wrapper.appendChild(uploadButton);
		uploadButton.addEventListener("click", e => {
			e.preventDefault();
			fileInput.click();
		});

		setTimeout(fileRenderAll,0);
	}
};
setTimeout(initExerciseFiles, 0);

