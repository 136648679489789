import type { frontendConfig } from '../back_end/ProjectConfig';

declare const isMonolith: boolean;
type ContentPipelineConfiguration = ReturnType<typeof frontendConfig>

export declare const configuration: ContentPipelineConfiguration;
export default {
	...configuration,
	isMonolith
};
