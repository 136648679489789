import { apiDeleteMessage, apiGetStudentMessages, apiMarkMessageAsRead, IMessage } from "@digitale-lernwelten/ugm-client-lib";
import { DataStore } from "../datastore";
import { isStudent } from "../user-data";

export interface IMessageEvent {
	id: string,
	type: "init" | "read" | "delete" | "add-or-update" | "flush",
	msg?: IMessage
}

const triggerListeners = (detail:IMessageEvent) => {
	window.dispatchEvent(new CustomEvent("ugm-message", {detail}));
};

const addOrUpdateMessage = (msg:IMessage) => {
	if(msg.deletedAt){
		deleteMessage(msg.id);
		return;
	}
	triggerListeners({type: "add-or-update", id: msg.id, msg});
	window.dispatchEvent( new Event("ugm-modal-message-update"));
};

const doPoll = async (after?: Date) => {
	if(!isStudent()){return [];}
	return await apiGetStudentMessages(after);
};

const flushMessages = () => {
	triggerListeners({type: "flush", id: ""});
};

const store:DataStore<IMessage> = new DataStore("ugm-messages", addOrUpdateMessage, flushMessages, doPoll);

export const deleteMessage = async (id:string) => {
	const msg = store.get(id);
	if(!msg){return;}
	store.delete(id);
	if(!msg.deletedAt){
		await apiDeleteMessage(id);
	}
	triggerListeners({type: "delete", id});
};
export const getMessages = () => Array.from(store.values());
export const getMessageCount = () => store.size();
export const getUnreadMessageCount = () => {
	let count = 0;
	store.forEach(m => {
		if(m.isRead || m.deletedAt){return;}
		count++;
	});
	return count;
};
export const getMessageById = (id:string) => store.get(id);
export const markMessageAsRead = (id:string) => {
	const msg = getMessageById(id);
	if(!msg){return;}
	if(msg.isRead){return;}
	msg.isRead = true;
	apiMarkMessageAsRead(id);
	triggerListeners({type: "read", id});
};

