import {apiGetCourses, isStudent as apiIsStudent, isTeacher as apiIsTeacher, TCourse} from '@digitale-lernwelten/ugm-client-lib';
import configuration from '../../configuration';

const COURSE_CACHE_TIME_TO_LIVE_IN_MS = 60 * 1000; // 60 seconds

let userData:any = null;
export const isLoggedIn = ():boolean => Boolean(userData);
export const isStudent = ():boolean => userData && (userData.role === "student" || apiIsStudent(userData));
export const isFakeStudent = ():boolean => apiIsTeacher(userData);
export const isTeacher = ():boolean => userData && (userData.role === "teacher" || !userData.role) && apiIsTeacher(userData);
export const getUser = ():any => userData;
export const setUser = (user:any) => {
	userData = user;
	storeUserDataInLocalStorage();
};
export const getCourse = ():TCourse | null => {
	if (!configuration.ugmEndpoint) { return null; }
	if(!isTeacher()){return null;}
	return userData?.meta?.activeCourse as TCourse | null;
};

interface CourseCache {
	validUntil: number,
	courses: TCourse[],
}

const courseCache:CourseCache = {
	validUntil: 0,
	courses: []
};

export const getCourseList = async ():Promise<TCourse[]> => {
	if (!configuration.ugmEndpoint) { return []; }
	if(!isTeacher()){return [];}
	const now = new Date().getTime();
	try {
		if (courseCache.validUntil < now) {
			const newCourses = await apiGetCourses();
			courseCache.courses = newCourses;
			courseCache.validUntil = now + COURSE_CACHE_TIME_TO_LIVE_IN_MS;
		}
		return courseCache?.courses || [];
	} catch {
		return [];
	}
};

export const clearCourseCache = () => {
	courseCache.validUntil = 0;
	courseCache.courses = [];
};
window.addEventListener("ugm-logged-out", clearCourseCache);

const loadUserDataFromLocalStorage = () => {
	if (!configuration.ugmEndpoint) { return; }
	const data = window.localStorage.getItem("user-data");
	try {
		if(!data){return;}
		userData = JSON.parse(data);
	} catch {
		console.log("Warning: can't parse user-data");
		console.log(data);
		/* Just ignore it, the erroneous data will be overwritten on then next login */
	}
};
loadUserDataFromLocalStorage();

const storeUserDataInLocalStorage = () => {
	if (!configuration.ugmEndpoint) { return; }
	if(!userData){
		window.localStorage.removeItem("user-data");
	}else{
		window.localStorage.setItem("user-data", JSON.stringify(userData));
	}
};

