import "./license.css";

export const initRights = () => {
	const getEmbeddingWrap = (ele:HTMLElement):HTMLElement | null => {
		if (!ele) { return null;}
		if (ele.tagName === "FIGURE" && ele.getAttribute("figure-type") === "embedding") {
			return ele;
		}
		if (ele.parentElement) {
			return getEmbeddingWrap(ele.parentElement);
		}
		return null;
	};

	const embedLinker = (licenseEle:HTMLElement, licenseContent:HTMLElement):boolean => {
		if (!licenseEle || !licenseContent) { return false;}
		const parentWrap = getEmbeddingWrap(licenseEle);
		if (!parentWrap) { return false;}
		const link = parentWrap.querySelector<HTMLAnchorElement>("a.embedding-link");
		if (!link) { return false;}
		const licenseLink = document.createElement("a");
		licenseLink.href = link.href;
		licenseLink.innerText = link.href;
		licenseLink.target = "_blank";
		licenseContent.append(licenseLink);
		return true;
	};

	document.querySelectorAll("details.license > summary").forEach(license => {
		const parent = license.parentElement;
		if(parent === null){return;}
		const licenseContent = parent.lastElementChild;
		if((licenseContent === null) || (licenseContent.innerHTML.trim().length === 0)){
			const embedLinked = embedLinker(license as HTMLElement, licenseContent as HTMLElement);
			if (!embedLinked) {
				license.classList.add("missing-attribution");
			}
		}
		parent.setAttribute("open","");
		license.addEventListener('click',(e) => {
			e.preventDefault();
			e.stopPropagation();
			parent.classList.toggle("active");
		});
	});
};
setTimeout(initRights, 0);
