import { hideOverlay } from "./overlay/overlay";
import { showModal } from "./modal/modal";
import Configuration from "../configuration";

export const iamTeacher = async (v:boolean) => {
	if(v){
		localStorage.setItem('isTeacher', 'true');
		continuationsWaitingForIsTeacher.forEach(c => c(null));
	}else {
		localStorage.removeItem('isTeacher');
	}
};

export const teacherTrue = () => localStorage.getItem('isTeacher') === 'true';

const continuationsWaitingForIsTeacher:((value: unknown) => void)[] = [];
export const continueWhenTeacher = () => new Promise(resolve => {
	continuationsWaitingForIsTeacher.push(resolve);
});

export const canUseTeacher = () => new Promise(resolve => {
	if(teacherTrue()){ resolve(true); return; }
	hideOverlay();

	const modal = showModal(`<p style="text-align: center; hyphens: none;">Hiermit bestätige ich den Einsatz des Infoportals zu Unterrichts- und Bildungszwecken.</p>
		<div id="privacy-btn-wrap">
		<button button-type="accept">bestätigen</button>
		</div>`);
	const acceptButton = modal.querySelector<HTMLElement>(`button[button-type="accept"]`);
	if(acceptButton){
		acceptButton.onclick = async () => {
			await iamTeacher(true);
			hideOverlay();
			return resolve(true);
		};
		acceptButton.focus();
	}
});

if (Configuration.educBanner) {
	//somehow we only can start it  if everything is loaded
	window.addEventListener("load", canUseTeacher);
}
