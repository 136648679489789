import { showEmbeddingSections } from "../embedding/embedding";
import { hideElementContentHandler, scrollToElement } from "../../_helper";
import { initSupportSlider } from "./support-slider";
import Config from "../../../configuration";
import { initWorkingTaskSlider } from "./working-tasks";

import "./tab-box.css";

const initDefaultTabBox = (box:HTMLElement) => {
	const innerContent = box.querySelector<HTMLElement>("inner-content");
	if(!innerContent){return;}
	const tabHeader  = Array.from(innerContent.querySelectorAll<HTMLElement>("tab-box-header"));
	const tabContent = Array.from(innerContent.querySelectorAll<HTMLElement>("tab-box-content"));
	const tabSource  = Array.from(innerContent.querySelectorAll<HTMLElement>("tab-box-source"));
	const tabWrappers = Array.from(innerContent.querySelectorAll<HTMLElement>("tab-box-header-wrap"));

	showEmbeddingSections(innerContent.querySelector<HTMLElement>("tab-box-content.active"));

	const activateTwin = (tab:HTMLElement) => {
		const index = tab.getAttribute("tab-index");
		tabWrappers.forEach((wrapper => {
			for (let i = 0; i < wrapper.children.length; i++) {
				const tabTwin = wrapper.children[i];
				if (tabTwin.getAttribute("tab-index") === index) {
					tabTwin.classList.add("active");
				}
			}
		}));
	};

	tabHeader.forEach((header => {
		const curIndex = parseInt(header.getAttribute('tab-index') || "0");
		let curContent:HTMLElement | null = null;
		let curSource:HTMLElement | null = null;
		tabContent.forEach((cContent)=>{
			const curContentIndex = parseInt(cContent.getAttribute('tab-index') || "0");
			if(curIndex !== curContentIndex){return;}
			curContent = cContent;
		});
		tabSource.forEach((cSource)=>{
			const i = parseInt(cSource.getAttribute('tab-index') || "0");
			if(curIndex !== i){return;}
			curSource = cSource;
		});
		if(!curContent){return;}
		header.addEventListener('click',() => {
			tabHeader.forEach((cHeader)=>{cHeader.classList.remove('active');});
			tabContent.forEach((cContent)=>{cContent.classList.remove('active'); hideElementContentHandler(cContent);});
			tabSource.forEach((cSource)=>{cSource.classList.remove('active');});
			header.classList.add('active');
			curContent?.classList.add('active');
			if(curSource){
				curSource.classList.add('active');
			}
			showEmbeddingSections(curContent);
			activateTwin(header);
			scrollToElement(header, 64);
		});
	}));
};

/* this function creates the right markup before the tabboxes get activated*/
const constructTabs = (elements:HTMLElement[]) => {
	elements.forEach(ele => {
		const innerContent = ele.querySelector<HTMLElement>("inner-content");
		const TabContainer = ele.querySelectorAll<HTMLElement>("tab-box-panel");
		if (!TabContainer || !innerContent) { return; }
		const headerWrap = document.createElement("TAB-BOX-HEADER-WRAP");
		const contentWrap = document.createElement("TAB-BOX-CONTENT-WRAP");
		innerContent.append(headerWrap);
		innerContent.append(contentWrap);
		TabContainer.forEach((panel, step) => {
			// put header and content into the new tabwrapper
			const boxPanelHeader = panel.querySelector<HTMLElement>("tab-box-header");
			const boxPanelContent = panel.querySelector<HTMLElement>("tab-box-content");
			const boxPanelSource = panel.querySelector<HTMLElement>("tab-box-source");

			if(boxPanelHeader){
				setTabIndex(boxPanelHeader, step);
				headerWrap.append(boxPanelHeader);
			}
			if(boxPanelContent){
				setTabIndex(boxPanelContent, step);
				contentWrap.append(boxPanelContent);
			}
			if (boxPanelSource) {
				setTabIndex(boxPanelSource, step);
				contentWrap.append(boxPanelSource);
			}
		});
		const clonedHeaderWrap = headerWrap.cloneNode(true);
		innerContent.append(clonedHeaderWrap);
	});
};

const setTabIndex = (element:HTMLElement | null, counter:number) => {
	if (!element) { return; }
	element.setAttribute("tab-index", String(counter));
	if (counter === 0) {
		element.classList.add("active");
	}
};

const initTabBoxes = () => {
	if(Config.isMonolith){
		const monolithBoxes = Array.from(document.querySelectorAll<HTMLElement>('section[content-type="tab-box"][enviroment="monolith"]'));
		constructTabs(monolithBoxes);
	}

	document.querySelectorAll<HTMLElement>('section[content-type="tab-box"]').forEach(box => {
		const boxType = box.getAttribute('tab-box-type');
		switch(boxType){
		case "support":
			initSupportSlider(box);
			break;
		case "workingtasks":
			initWorkingTaskSlider(box);
			break;
		default:
			initDefaultTabBox(box);
			break;
		}
	});
};
setTimeout(initTabBoxes, 0);
