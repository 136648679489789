import { getCourse, getUser } from "../user-data";
import { showSidebar } from "../sidebar/sidebar";
import { getUnreadNotificationCount } from "../user-group-management";
import configuration from "../../../configuration";
import t from "../../i18n";

import "./headerbar.css";

const refreshMVetHeaderButton = () => {
	let headerButton = document.querySelector<HTMLElement>(".course-button");
	if(!headerButton){
		headerButton = document.createElement("div");
		headerButton.classList.add("course-button");
		headerButton.addEventListener("click", e => {
			e.preventDefault();
			showSidebar();
		});
		headerButton.innerHTML = "<span></span>";
		document.querySelector("header")?.insertBefore(headerButton, document.querySelector("login-button"));
	}

	const nameSpan = headerButton.querySelector<HTMLElement>("span");
	if(nameSpan){
		nameSpan.innerHTML = t().mvet.myMvet;
	}
};

const refreshUGMHeaderButton = () => {
	let headerButton = document.querySelector<HTMLElement>(".course-button");
	if(!headerButton){
		headerButton = document.createElement("div");
		headerButton.classList.add("course-button");
		headerButton.addEventListener("click", e => {
			e.preventDefault();
			showSidebar();
		});
		headerButton.innerHTML = "<span></span><msg-counter></msg-counter>";
		document.querySelector("header")?.insertBefore(headerButton, document.querySelector("login-button"));
	}
	const course = getCourse();
	const user = getUser();

	const nameSpan = headerButton.querySelector<HTMLElement>("span");
	if(nameSpan){
		nameSpan.innerText = course?.name || user?.loginName || "";
	}

	const counter = headerButton.querySelector("msg-counter") as HTMLElement;
	const msgCount = getUnreadNotificationCount();
	counter.innerText = `${msgCount}`;
	if(msgCount === 0){
		counter.classList.add("no-unread-messages");
	} else {
		counter.classList.remove("no-unread-messages");
	}
};

const refreshHeaderButton = () => {
	switch(configuration.userSidebar){
	case "mvet":
		refreshMVetHeaderButton();
		break;
	default:
		refreshUGMHeaderButton();
		break;
	}
};
setTimeout(refreshHeaderButton, 0);
window.addEventListener("ugm-correction", refreshHeaderButton);
window.addEventListener("ugm-course-changed", refreshHeaderButton);
window.addEventListener("ugm-logged-in", refreshHeaderButton);
window.addEventListener("ugm-logged-out", refreshHeaderButton);
window.addEventListener("ugm-header-update", refreshHeaderButton);

