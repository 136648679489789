const transitionDuration = 300;
const mobileWidthBreakpoint = 500;

import "./tiles.css";

const removeAnimated = (tile:Element) => {
	tile.classList.remove("active");
	const child = tile.querySelector("tile-children");
	child?.classList.remove("active");
	setTimeout(() => tile.remove(), transitionDuration + 50);  // transitionend sadly can't be trusted on mobile devices
};

const showTileDesktop = (tile:Element, clonedTile:HTMLElement) => {
	clonedTile.classList.add("absolute-clone");
	clonedTile.classList.add("clone");
	tile.parentElement?.appendChild(clonedTile);
	clonedTile.offsetTop; // Forces the browser to apply all pending updates
};

const showTileMobile = (tile:Element, clonedTile:HTMLElement, tileWrap:Element) => {
	clonedTile.classList.add("fixed-clone");
	clonedTile.classList.add("clone");
	const rect = tile.getBoundingClientRect();
	clonedTile.style.left = `${rect.x|0}px`; // |0 to enforce ints since certain browsers like to return floating point values here
	clonedTile.style.top = `${rect.y|0}px`;
	clonedTile.style.width = `${rect.width|0}px`;
	clonedTile.style.height = `${rect.height|0}px`;
	tileWrap.appendChild(clonedTile);
	clonedTile.offsetTop; // Forces the browser to apply all pending updates
};

const aggregateTiles = (tileWrap:Element) => {
	const children = tileWrap.children;
	const aggs:HTMLElement[] = [];
	while(children.length > 0){
		const agg = document.createElement("tile-square");
		aggs.push(agg);
		for(let i=0;i<4;i++){
			if(children.length === 0){break;}
			children[0].classList.add((i%2 === 0) ? "left" : "right");
			children[0].classList.add(i<2 ? "top" : "bottom");
			agg.appendChild(children[0]);
		}
	}
	for(let i=0;i<aggs.length;i++){
		tileWrap.appendChild(aggs[i]);
	}
};

const tileLinkClickEvent = (tiles:NodeListOf<Element>) => {
	tiles.forEach((tile:Element) => {
		tile.addEventListener("click", ()=>{
			const rawUrl = tile.getAttribute("href");
			if (!rawUrl) { return;}
			try {
				const url = new URL(`${window.location.protocol}${window.location.host}${rawUrl}`);
				window.open(url.href, "_blank");
			} catch (e) {
				console.log("frontend tiles script wrong href",e);
				return;
			}
		});
	});
};

const initTiles = (tileWrap:Element) => {
	aggregateTiles(tileWrap);
	//new switch on wrap tile-type wich opens new tab instead of the tile transforming
	const tileType = tileWrap.getAttribute("tile-type");
	const tiles = tileWrap.querySelectorAll(".title-link");
	if (!tiles) { return;}
	if (tileType === "link") {
		tileLinkClickEvent(tiles);
		return;
	}
	//resume transform behavior
	tiles.forEach((tile, i) => {
		tile.addEventListener("click", () => {
			//remove all other old nodes
			document.querySelectorAll(".absolute-clone").forEach(removeAnimated);
			const clonedTile = tile.cloneNode(true) as HTMLElement;
			if(i % 2){
				clonedTile.classList.add("animate-left");
			}

			if (window.innerWidth > mobileWidthBreakpoint) {
				showTileDesktop(tile, clonedTile);
			} else {
				showTileMobile(tile, clonedTile, tileWrap);
			}

			clonedTile.style.color = "blue";
			clonedTile.classList.add("active");
			setTimeout(() => clonedTile.classList.add("scrollable"), transitionDuration + 50); // transitionend sadly can't be trusted on mobile devices
			setTimeout(() => clonedTile.querySelector("tile-children")?.classList.add("active"), 150);
			const closeButton = clonedTile.querySelector("close-tile");
			closeButton?.addEventListener("click", () => {
				removeAnimated(clonedTile);
			});
		});
	});
};
export const initAllTiles = () => document.querySelectorAll("tile-wrap").forEach(initTiles);
setTimeout(initAllTiles, 0);
