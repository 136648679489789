import { hideOverlay } from "../../overlay/overlay";
import { showModal } from "../../modal/modal";
import { setSubmission, submitExercise, getSubmission } from "../../user-group-management/submissions/submissions";
import { getFirstParentSection } from "../../_helper";
import { initRTE } from "../../rte";
import configuration from "../../../configuration";
import { isLoggedIn } from "../../user-group-management/user-data";
import t from "../../i18n";

import "./exercise-textarea.css";

const renderHTMLFromQuillOps = (ops:any) => {
	let ret = '';
	let curLine = '';
	let listActive = '';
	for(const op of ops){
		let cur = op.insert;
		if(op.attributes?.bold)      {cur = `<b>${cur}</b>`;}
		if(op.attributes?.italic)    {cur = `<i>${cur}</i>`;}
		if(op.attributes?.underline) {cur = `<u>${cur}</u>`;}
		if(op.attributes?.strike)    {cur = `<s>${cur}</s>`;}
		if(op.attributes?.color)     {cur = `<span style="color:${op.attributes.color}">${cur}</span>`;}
		if(op.attributes?.background){cur = `<span style="background-color:${op.attributes.background}">${cur}</span>`;}
		if(op.insert === "\n"){
			if(listActive !== op.attributes?.list){
				if(listActive === 'ordered'){ret += '</ol>';}
				else if(listActive === 'bullet'){ret += '</ul>';}

				listActive = op.attributes?.list;

				if(listActive === 'ordered'){ret += '<ol>';}
				else if(listActive === 'bullet'){ret += '<ul>';}
			}
			ret += listActive ? `<li>${curLine}</li>` : `<p>${curLine}</p>`;
			curLine = '';
		}else{
			curLine += cur;
		}
	}
	ret += curLine;
	if(listActive === 'ordered'){ret += '</ol>';}
	else if(listActive === 'bullet'){ret += '</ul>';}
	return ret;
};

const loadData = (localStorageKey:string) => localStorage.getItem(localStorageKey);
const loadQuillData = (quillLocalStorageKey:string) => {
	const loadedContentJSON = localStorage.getItem(quillLocalStorageKey);
	if (loadedContentJSON !== null) {
		try {
			const loadedContent = renderHTMLFromQuillOps(JSON.parse(loadedContentJSON).ops);
			return loadedContent;
		} catch (e) {
			localStorage.removeItem(quillLocalStorageKey);
		}
	}
	return null;
};

const lockedExercises:Set<string> = new Set();
const editors = new Map();
export const lockExercise = (exerciseId:string) => {
	const {editor, submitButton} = editors.get(exerciseId);
	if(!editor){return;}
	editor.enableReadOnlyMode('exercise-ugm');
	submitButton.classList.add('locked');
	submitButton.innerText = t().correctionSubmitted;
	lockedExercises.add(exerciseId);
};

export const unlockExercise = (exerciseId:string) => {
	const v = editors.get(exerciseId);
	if(!v){return;}
	v.editor.disableReadOnlyMode('exercise-ugm');
	if (v.submitButton) {
		v.submitButton.classList.remove('locked');
		v.submitButton.innerText = t().submitCorrection;
	}
	lockedExercises.delete(exerciseId);
};

const getInitialData = (id:string) => {
	if(configuration.ugmEndpoint){
		const sub = getSubmission(id); // If we have a submission we prioritize that over anything we found in localstorage
		if(sub){
			return sub.text;
		} else {
			if (isLoggedIn()){
				return "";
			}
		}
	}
	const quillLocalStorageKey = `quill-${id}`;
	const localStorageKey = `ck-${id}`;
	return loadData(localStorageKey) || loadQuillData(quillLocalStorageKey) || '';
};

const showLockedModal = () => {
	hideOverlay();
	const modal = showModal(`<h3>${t().correctionSubmittedModal}</h3>
	<p>${t().editLocked}</p>
	<div class="button-wrap-right"><button class="dlw">OK</button><div>`);
	const button = modal.querySelector("button");
	if(button){
		button.onclick = hideOverlay;
	}
};

const initExerciseEditor = async (area:HTMLElement) => {
	const section = getFirstParentSection(area);
	const id = (section && section.getAttribute("content-type-id")) || "undefined";
	const fancyEditor = document.createElement("fancy-exercise-editor");
	area.parentElement?.append(fancyEditor);
	area.parentElement?.removeChild(area);
	const initialData = getInitialData(id);
	const editor = await initRTE(fancyEditor, initialData, t().editTask);

	let delayedWriteHandle:ReturnType<typeof setTimeout> | null = null;
	editor.model.document.on('change:data', () => {
		if (delayedWriteHandle) {
			clearTimeout(delayedWriteHandle);
			delayedWriteHandle = null;
		}
		delayedWriteHandle = setTimeout(() => {
			delayedWriteHandle = null;
			setSubmission(id, {text: editor.getData()});
		}, 200); // Only write to localStorage after 200ms of inactivity
	});

	if(configuration.ugmEndpoint){
		if(!configuration.ugmNoSubmissions){
			const submitButton = document.createElement("button");
			submitButton.setAttribute("type", "submit");
			submitButton.classList.add("ugm-exercise-submit");
			submitButton.innerText = t().submitCorrection;
			submitButton.addEventListener("click", () => {
				if(lockedExercises.has(id)){
					showLockedModal();
				} else {
					submitExercise(id, editor.getData());
				}
			});
			fancyEditor.parentElement?.append(submitButton);
			editors.set(id, {editor, submitButton});
		} else {
			editors.set(id, {editor});
		}
		const sub = getSubmission(id);
		if((sub?.state === "submitted") || (sub?.state === "corrected")){
			if(!(sub.correction?.read)){
				lockExercise(id);
			}
		}
	}
};

const initExerciseEditors = () => document.querySelectorAll<HTMLElement>(".exercise-text").forEach(initExerciseEditor);
setTimeout(initExerciseEditors, 0);
