import configuration from "../../../configuration";
import { getFirstParentSection } from "../../_helper";
import { initGlossary } from "../../content_types/glossary/glossary";
import { initAllNoteMarkers } from "../notes/notes";
import {
	apiAddMark,
	apiDeleteMark,
	apiGetMarks,
	apiUpdateMark,
	IMark,
	IMarkData
} from "@digitale-lernwelten/ugm-client-lib";

import { DataStore } from "../datastore";
import { initGallery, initLightbox } from "../../content_types/gallery/gallery";

import "./mark.css";
import t from "../../i18n";

export type MarkColor = "blue" | 'red' | "green" | "yellow";

export interface Mark {
	customId: string;
	markNumber: number;
	contentId: string;
	uniqueText: string;
	selectionText: string;
	selectionOffset: number;
	selectionLength: number;
	color: MarkColor;
	deletedAt: Date | null;
}

const convertLocalToRemote = (mark: Mark): IMarkData => {
	return ({
		customId: mark.customId,
		contentId: mark.contentId,
		uniqueText: mark.uniqueText,
		selectionText: mark.selectionText,
		selectionOffset: mark.selectionOffset,
		selectionLength: mark.selectionLength,
		color: mark.color,
		deletedAt: mark.deletedAt
	});
};

const originalHTMLStore:Map<HTMLElement, string> = new Map();

const wrapTextSection = (
	ele:HTMLElement,
	sections:any,
	wrapBefore:string,
	wrapAfter:string
) => {
	// We need to store the original HTML before we apply any marks so that we can remove them when we have
	// to refresh them all.
	if(!originalHTMLStore.has(ele)){
		originalHTMLStore.set(ele, ele.innerHTML);
	}

	const markup = ele.innerHTML;
	if(sections.length === 0){return;}
	let newMarkup = '';
	let markupPos = 0;
	for(let i = 0; i < sections[0].length; i++){
		newMarkup += markup.substring(markupPos, sections[0][i].textStart);
		markupPos = sections[0][i].textStart;
		newMarkup += wrapBefore;
		if(sections[0][i].textEnd === undefined){sections[0][i].textEnd = sections[0][i].textStart;}
		newMarkup += markup.substring(markupPos, sections[0][i].textEnd);
		newMarkup += wrapAfter;
		markupPos = sections[0][i].textEnd;
	}
	newMarkup += markup.substring(markupPos, markup.length);
	ele.innerHTML = newMarkup;
};
const addMarkHighlight = (ele:HTMLElement, mark:IMark) => {
	const sections = searchTextSection(ele.innerHTML, mark.uniqueText, mark.selectionOffset, mark.selectionLength);
	const time = (typeof mark.updatedAt === "string") ? (new Date(mark.updatedAt).getTime()) : mark.updatedAt.getTime();
	wrapTextSection(
		ele,
		sections,
		// the dates get transformed to a proper date object in the polling
		`<span class="mark" mark-color="${mark.color}" mark-id="${mark.id}" updated-at="${time}">`,
		'</span>'
	);
	initAllNoteMarkers();
	initGlossary();
	initMarkCallbacks();
	initGallery();
	initLightbox();
};

const refreshAllMarks = ( ) => {
	// First we remove all active mark-highlights
	originalHTMLStore.forEach((html, ele) => { ele.innerHTML = html; });
	originalHTMLStore.clear(); // Just a precaution

	// And add them all yet again
	store.forEach(m => {
		const ele = document.querySelector<HTMLElement>(`section[content-type-id="${m.contentId}"]`);
		if(ele){
			addMarkHighlight(ele, m);
		}
	});
};

const refreshMark = (mark: IMark) => {
	if(!mark){return;}

	// remove highlight
	const markElement = document.querySelector(`.mark[mark-id="${mark.id}"]`);
	if(markElement){
		const time = (typeof mark.updatedAt === "string") ? new Date(mark.updatedAt).getTime() : mark.updatedAt.getTime();
		if(time <= Number(markElement.getAttribute("updated-at"))){
			return;
		}
		markElement.replaceWith(markElement.innerHTML);
	}

	//add highlight
	const ele = document.querySelector<HTMLElement>(`section[content-type-id="${mark.contentId}"]`);
	if(ele){
		addMarkHighlight(ele, mark);
	}

};

const deleteMark = async (id: string) => {
	const mark = store.get(id);
	if(!mark || !mark.id){
		return;
	}
	store.delete(mark.id);
	if(configuration.ugmEndpoint) {
		await apiDeleteMark(mark.id);
	}
	refreshAllMarks();
};

const addOrUpdateMark = (mark: IMark) => {
	if(mark.deletedAt){
		const oldMark = store.get(mark.id);
		// Only remove the mark if IDs match
		if(oldMark && (oldMark.id === mark.id)){
			deleteMark(oldMark.id);
		}
	} else {
		refreshMark(mark);
	}

};

const doPoll = async (after?: Date): Promise<IMark[]>  => {
	try {
		if(!configuration.enableMarks){
			return [];
		}
		//do not refresh if a menu is open (it fucks up the menu and makes it unclosable)
		if(document.querySelector("mark-menu")){
			return [];
		}
		let marks = await apiGetMarks(after);
		marks = marks.map(m => ({
			...m,
			createdAt: new Date(m.createdAt),
			updatedAt: new Date(m.updatedAt),
			deletedAt: m.deletedAt === null ? null : new Date(m.deletedAt)
		}));
		marks.forEach((m) => {
			const markNum = parseInt(m.customId.split('_')[1]);
			markNumberCounter = Math.max(markNumberCounter, markNum+1);
		});

		return marks;
	} catch {
		console.log("ERROR: Could not get marks of current user from remote.");
		return [];
	}
};



const searchTextSection = (
	markup:string,
	text:string,
	selectionOffset = 0,
	selectionLength = text.length,
	caseSensitive = true
) => {
	if(!caseSensitive){
		text = text.toLowerCase();
	}

	let ti = 0;
	let ct = text.charAt(ti);

	const retTextSections = [];
	let textSections:any[] = [];
	let textSectionOpen = false;

	const tags:any[] = [];
	const openTags:any[] = [];
	let curTag      = '';
	let curTagStart = 0;

	let inOpenTag        = false;
	let inOpenClosingTag = false;
	let inOpenAttribute  = false;
	let inOpenTagName    = false;

	let inEscapedChar  = false;
	let escapedChar    = '';
	let wasEscapedChar = false;

	let cl = '';
	let cc = '';

	for(let i=0;i<markup.length;i++){
		if(!inEscapedChar){
			cl = cc;
		}
		cc = markup.charAt(i);
		if(!caseSensitive){
			cc = cc.toLowerCase();
		}
		wasEscapedChar = false;

		if(inEscapedChar){
			if(cc === ';'){
				inEscapedChar = false;
				switch(escapedChar){

				case 'shy':
					cc = String.fromCharCode(173); // shy
					wasEscapedChar = true;
					break;
				case 'amp':
					cc = '&';
					wasEscapedChar = true;
					break;
				case 'lt':
					cc = '<';
					wasEscapedChar = true;
					break;
				case 'gt':
					cc = '>';
					wasEscapedChar = true;
					break;
				case 'nbsp':
					cc = String.fromCharCode(160); // nbsp
					wasEscapedChar = true;
					break;
				default:
					break;
				}
			}else{
				if(cc !== null){
					escapedChar += cc;
				}
				continue;
			}
		}
		if(inOpenAttribute){
			if(cc === '"'){inOpenAttribute=false;}
		}else if(inOpenTag){
			if(cc === '>'){

				if(inOpenClosingTag){
					openTags[openTags.length-1].tagCloseName = curTag;
					openTags[openTags.length-1].tagCloseStart = curTagStart;
					openTags[openTags.length-1].tagCloseEnd = i+1;

					if(curTag !== openTags[openTags.length-1].tagName){
						console.log('Error: open/closing Tag Names do not match');
						console.log(openTags);
					}

					tags.push(openTags.pop());

				} else {
					const tmp:any = {};
					tmp.tagName = curTag;
					tmp.tagStart = curTagStart;
					tmp.tagEnd = i+1;
					openTags.push(tmp);

					let isSingleton = false;
					if(markup.charAt(i-1) === '/'){
						isSingleton = true;
						curTag = curTag.substring(0,curTag.length-1);
					}else{
						switch(curTag){
						case 'area':
						case 'base':
						case 'br':
						case 'col':
						case 'command':
						case 'embed':
						case 'hr':
						case 'img':
						case 'input':
						case 'link':
						case 'meta':
						case 'param':
						case 'source':
							isSingleton = true;
							break;
						default:
							break;
						}
					}

					if(isSingleton){
						openTags[openTags.length-1].tagName = curTag;
						openTags[openTags.length-1].tagCloseName = openTags[openTags.length-1].tagName;
						openTags[openTags.length-1].tagCloseStart = openTags[openTags.length-1].tagStart;
						openTags[openTags.length-1].tagCloseEnd = openTags[openTags.length-1].tagEnd;
						tags.push(openTags.pop());
					}
				}
				inOpenTagName = false;
				inOpenTag = false;
				inOpenClosingTag = false;
			}else if(cc === '"'){
				inOpenAttribute = true;
			}else if(inOpenTagName){
				if(cc === ' '){
					inOpenTagName = false;
				}else{
					if((curTag === '') && (cc === '/')){
						inOpenClosingTag = true;
					}else{
						curTag += cc;
					}
				}
			}
		}else{
			if((cc === '<') && !wasEscapedChar){
				if(textSectionOpen){
					textSections[textSections.length-1].textEnd = i;
				}
				textSectionOpen = false;
				inOpenTag = true;
				inOpenTagName = true;
				curTagStart = i;
				curTag = '';
			}else if((cc === '&')&&!wasEscapedChar){
				inEscapedChar = true;
				escapedChar = '';
			}else{
				if(cc === String.fromCharCode(173)){continue;} // shy
				if(cc === "\n"){continue;}
				if(cc === "\r"){continue;}
				if(cc === "\t"){continue;}
				if((cc === ' ') && (cl === ' ')){continue;}


				if(cc === ct){
					if(ti < selectionOffset){
						for(let ctii=0;ctii<10;ctii++){
							ct = text.charAt(++ti);
							if(ct === String.fromCharCode(173)){continue;} // shy
							break;
						}

						continue;
					}
					if(!textSectionOpen){
						textSections.push({});
						textSections[textSections.length-1].textStart = i;
						textSectionOpen = true;
					}
					ti++;
					if(ti === selectionOffset+selectionLength){
						textSections[textSections.length-1].textEnd = i+1;
					}
					if(ti === text.length){
						retTextSections.push(textSections);
						textSections = [];
						textSectionOpen = false;
						ti = 0;
					}
					ct = text.charAt(ti);
					while((ct === String.fromCharCode(10) || (ct === String.fromCharCode(173)))){
						ct = text.charAt(++ti);
					}
				}else{
					if(ti !== 0){
						ti = 0;
						ct = text.charAt(ti);
						textSections = [];
						textSectionOpen = false;
					}
				}
			}
		}
	}
	return retTextSections;
};

const setMarkColor = async (id:string, color: MarkColor) => {

	const receivedMark = await apiUpdateMark(
		{color, deletedAt: new Date()},
		id
	);

	if(receivedMark) {
		store.set(receivedMark);
		addOrUpdateMark(receivedMark);
		refreshAllMarks();
		const eles = document.querySelectorAll(`.mark[mark-id="${id}"]`);
		if(eles.length){
			for(const ele of Array.from(eles)){
				ele.setAttribute("mark-color", color);
			}
		}
	}

};

const showMarkMenu = (ele:HTMLElement) => {
	const markId = ele.getAttribute("mark-id");
	if(!markId){ return; }
	const section = getFirstParentSection(ele);
	if(!section){ return; }

	// remove the other instances of mark menus to avoid bugs
	const oldMenu = Array.from(document.getElementsByTagName("mark-menu"));
	oldMenu.forEach(om => om.remove());

	// create new mark menu
	const menu = document.createElement("mark-menu");
	const colors:MarkColor[] = ["blue", "red", "green", "yellow"];
	for(const color of colors){
		const badge = document.createElement("mark-color-change");
		badge.setAttribute("mark-color", color);
		badge.onclick = () => {
			menu.remove();
			setMarkColor(markId, color);
		};
		menu.append(badge);
	}
	const deleteMarkButton = document.createElement("mark-delete");
	deleteMarkButton.title = t().notesRemove;
	deleteMarkButton.onclick = () => {
		menu.remove();
		deleteMark(markId);
	};
	menu.append(deleteMarkButton);

	const closeMarkMenuButton = document.createElement("mark-close");
	closeMarkMenuButton.title = "Markierungsmenü schließen";
	closeMarkMenuButton.onclick = () => {
		menu.remove();
	};
	menu.append(closeMarkMenuButton);

	section.append(menu);
	const top = ele.offsetTop + ele.offsetHeight;
	menu.style.top = `${top}px`;
};

const initMarkCallback = (ele:HTMLElement) => {
	ele.onclick = e => {
		e.preventDefault();
		e.stopPropagation();
		showMarkMenu(ele);
	};
};

const initMarkCallbacks = () => {
	document.querySelectorAll("span.mark").forEach(initMarkCallback);
};


let markNumberCounter = 0;

const saveCurrentSelectionAsMark = async (selection:Selection) => {
	const range = selection.getRangeAt(0);
	const cnode = range.startContainer;
	if(!cnode){return;}

	if(range.commonAncestorContainer instanceof HTMLElement){
		if(range.commonAncestorContainer.querySelectorAll("section[content-type]").length > 1){
			range.setEndAfter(cnode);
		}
	}
	let uniqueText = range.toString();

	const ele = getFirstParentSection(cnode as HTMLElement);
	if(!ele){return;}

	const contentId = ele?.getAttribute("content-type-id");
	if(!contentId){return;}

	let sections = searchTextSection(ele.innerHTML, uniqueText);

	let selectionOffset = 0;
	const selectionLength = uniqueText.length;
	const origSOff = range.startOffset;
	let i = 0;
	while(sections.length > 1) {
		if (i++ > 20) {
			return;
		}

		// avoid index out of bounds
		const boundedStartOffset =
			range.startOffset > 9
				? range.startOffset - 10
				: range.startOffset;

		range.setStart(range.startContainer, Math.max(
			0,
			boundedStartOffset
		));


		// avoid index out of bounds
		let boundedEndOffset = range.endOffset + range.startOffset;
		if (range.endContainer.textContent) {
			if (range.endContainer.textContent.length < (range.endOffset + range.startOffset)) {
				boundedEndOffset = range.endContainer.textContent.length;
			}
		}

		range.setEnd(
			range.endContainer,
			boundedEndOffset
		);

		uniqueText = range.toString();
		selectionOffset = origSOff - range.startOffset;

		sections = searchTextSection(ele.innerHTML, uniqueText, selectionOffset, selectionLength);
	}
	const markNumber = ++markNumberCounter;
	const customId = `${contentId}_${markNumber}`;
	const selectionText = uniqueText.substring( selectionOffset, selectionOffset + selectionLength );

	const mark:Mark = {
		customId,
		markNumber,
		contentId,
		uniqueText,
		selectionText,
		selectionOffset,
		selectionLength,
		color: 'blue',
		deletedAt: null
	};


	if(configuration.ugmEndpoint) {
		const convertedMark = convertLocalToRemote(mark);
		const receivedMark = await apiAddMark(convertedMark);

		if(receivedMark) {
			store.set(receivedMark);
			addOrUpdateMark(receivedMark);
			refreshAllMarks();
		} else {
			console.log(`ERROR: Mark with customId '${mark.customId}' could not be added to remote.`);
		}
	}
};

const initNewMarkOverlay = () => {
	let markOverlayVisible = false;
	const markOverlay = document.createElement('div');
	markOverlay.id = "mark-overlay";

	const newMarkButton = document.createElement('button');
	newMarkButton.id = "new-mark-button";
	newMarkButton.setAttribute("unselectable", "on");
	newMarkButton.title = t().notesAdd;
	newMarkButton.setAttribute("aria-label", t().notesAdd);
	markOverlay.append(newMarkButton);
	document.body.append(markOverlay);

	let lastSelection: Selection | null = null;
	const refreshMarkOverlay = () => {
		const selection = window.getSelection();
		if(!selection || selection.anchorNode === undefined || selection.anchorNode === null || selection.anchorNode.parentNode === undefined || selection.anchorNode.parentNode === null){
			if(markOverlayVisible){
				markOverlayVisible=false;
				markOverlay.classList.remove('visible');
			}
			return;
		}

		if(selection.isCollapsed){
			if(markOverlayVisible){
				markOverlayVisible=false;
				markOverlay.classList.remove('visible');
			}
			return;
		}
		lastSelection = selection;
		const range = selection.getRangeAt(0).cloneRange();
		const brect = range.getBoundingClientRect();
		const ww = window.outerWidth;
		if(ww < 1240){
			markOverlay.style.top = (brect.bottom + window.scrollY)+"px";
			markOverlay.style.left = ((brect.left + brect.right)/2)+"px";
		}else{
			markOverlay.style.top = ((brect.top + (brect.height/2)) + window.scrollY) + "px";
		}
		if(!markOverlayVisible){
			markOverlayVisible=true;
			markOverlay.classList.add('visible');
		}
		//if(to){
		// setTimeout(function(){amnRefreshNotizenTB(false)},10);
		//}
	};

	document.addEventListener("selectionchange", refreshMarkOverlay);
	document.addEventListener("mouseup", refreshMarkOverlay);
	document.addEventListener("touchend", refreshMarkOverlay);

	const newMarkButtonCB = async (event:Event) => {
		event.preventDefault();
		event.stopPropagation();
		//if(!markOverlayVisible){return;}
		const selection = window.getSelection() || lastSelection;
		if(!selection || selection.isCollapsed){return;}
		await saveCurrentSelectionAsMark(selection);
		selection.removeAllRanges();
		markOverlayVisible=false;
		markOverlay.classList.remove('visible');
	};
	const newMarkButtonMouseCB = async (event:MouseEvent) => {
		event.preventDefault();
		event.stopPropagation();
		//if(!markOverlayVisible){return;}
		if(event.button !== 0){
			return;
		}
		const selection = window.getSelection();
		if(!selection || selection.isCollapsed){return;}
		await saveCurrentSelectionAsMark(selection);
		selection.removeAllRanges();
		markOverlayVisible=false;
		markOverlay.classList.remove('visible');
	};
	newMarkButton.addEventListener("touchend", newMarkButtonCB);
	newMarkButton.addEventListener("mousedown", newMarkButtonMouseCB);
};

const flushMarks = () => {
	originalHTMLStore.forEach((html, ele) => { ele.innerHTML = html; });
	originalHTMLStore.clear();
};

const store:DataStore<IMark> = new DataStore(
	"ugm-marks",
	addOrUpdateMark,
	flushMarks,
	doPoll
);

if(configuration.ugmEndpoint && configuration.enableMarks){
	refreshAllMarks();
	setTimeout(initNewMarkOverlay, 0);
}
