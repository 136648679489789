import configuration from "../../configuration";
import { setCookie } from "../_helper";
import "./themeSwitcher.css";

const setTheme = (theme: string) => {
	setCookie("activeTheme", theme);
	location.reload();
};

export const initThemeSwitcher = () => {
	const select = document.createElement("select");
	select.innerHTML = configuration.themes.map(theme => `<option ${theme === configuration.activeTheme ? "selected" : ""}>${theme}</option>`).join("");
	select.id = "theme-switcher";
	document.querySelector<HTMLElement>("header")?.append(select);
	select.onchange = () => setTheme(select.value);
};

setTimeout(() => {
	if(configuration.themes.length > 1){
		initThemeSwitcher();
	}
},0);
