import { hideOverlay, showOverlay, overlayCloseHandlers } from "../../overlay/overlay";
import { isThirdPartyHref } from "../embedding/embedding";
import { embedsAllowed, canUseEmbeds } from "../../privacy/privacy";

import "./location.css";

let hideLocationCallback: (() => void) | null = null;

const initLocation = (locationSection:HTMLElement) => {
	const mapElement = locationSection.querySelector<HTMLElement>("lazy-iframe");
	const imageElement = locationSection.querySelector<HTMLElement>("location-image");
	const nextSection = locationSection.nextElementSibling;
	if (!nextSection) { return; }
	if (!mapElement && !imageElement) { return; }

	let oldX = 0;
	let oldY = 0;

	const locationButton = document.createElement("location-button");
	nextSection.append(locationButton);
	const hasHelpVideo = nextSection.querySelectorAll("help-video").length > 0;
	if (hasHelpVideo) {
		locationButton.classList.add("offset");
	}

	const hideLocation = () => {
		locationSection.style.transition = "left ease 600ms, top ease 400ms, ease width 500ms, height ease 500ms, border-radius ease 300ms";
		locationSection.style.left       = `${oldX}px`;
		locationSection.style.top        = `${oldY}px`;
		locationSection.style.width      = "2em";
		locationSection.style.height     = "2em";
		locationSection.style.borderRadius = "50%";
		locationSection.offsetTop;
		//wait before hiding
		setTimeout(() => {
			locationSection.style.zIndex = "0";
			if (mapElement) {
				destroyIframe(mapElement);
			}
			if (imageElement) {
				imageElement.style.display = "none";
			}
		}, 400);

		hideLocationCallback = null;
	};

	const showLocation = async () => {
		if (!nextSection) { return; }
		// This is so clicking on the button again while it is opening close it again
		if(hideLocationCallback){
			hideOverlay();
			return;
		}
		// Calculate screen position for button and set those before we set the transition propoerty
		// This is so the button starts moving from its old position, and we hide that we switch its
		// Position from absolute to fixed
		const max = Math.min(window.innerWidth,window.innerHeight) * 0.86;
		const rect = locationButton.getBoundingClientRect();
		oldX = (rect.left|0);
		oldY = (rect.top + window.scrollY|0);
		locationSection.style.overflow = "hidden";
		locationSection.style.left = oldX+"px";
		locationSection.style.top  = oldY+"px";
		locationSection.style.position = "absolute";
		locationSection.style.zIndex = "2001";
		locationSection.style.width = "2em";
		locationSection.style.height = "2em";
		locationSection.style.borderRadius = "50%";
		locationSection.style.alignItems = "center";
		locationSection.offsetTop;

		//now zoom the location and use all the space
		locationSection.style.transition = "left ease 600ms, top ease 400ms, width ease 500ms, height ease 500ms, border-radius ease 700ms";
		locationSection.style.display = "flex";
		locationSection.style.width   = max*0.79+"px";
		locationSection.style.height  = max*0.79+"px";
		locationSection.style.opacity = "1";
		locationSection.style.borderRadius = "8px";
		locationSection.offsetTop;
		locationSection.style.left = ((document.body.clientWidth/2 - (parseInt(locationSection.style.width)/2))|0 )+"px";
		locationSection.style.top  = window.scrollY + 100 + "px";

		showOverlay(locationSection);

		if (mapElement) {
			const source = mapElement.getAttribute("src");
			if (!source) { return;}

			//check privacy
			if(!embedsAllowed() && isThirdPartyHref(source || "")){
				mapElement.addEventListener("click", canUseEmbeds);
				mapElement.classList.add("waiting-for-approval");
				locationSection.classList.add("awaiting-allow");
				await canUseEmbeds();
				mapElement.removeEventListener("click", canUseEmbeds);
				mapElement.classList.remove("waiting-for-approval");
			}

			if (embedsAllowed()) {
				locationSection.classList.remove("awaiting-allow");
				//Lazy iframe activation
				const loadedIframe = buildIframe(mapElement);
				if (loadedIframe) {
					locationSection.classList.add("loading");
					mapElement.appendChild(loadedIframe);
					setTimeout(() => { showOverlay(locationSection);}, 0);
					loadedIframe.width   = max*0.79+"px";
					loadedIframe.height  = max*0.79+"px";
					loadedIframe.onload = () => locationSection.classList.remove("loading");
				}
			} else {
				locationSection.style.display = "none";
				hideOverlay();
				return;
			}
		} else {
			showOverlay(locationSection);
		}

		if (imageElement) {
			imageElement.style.display = "block";
		}

		hideLocationCallback = hideLocation;
	};
	locationButton.onclick = showLocation;
};

const buildIframe = (lazyIframe:HTMLElement) => {
	if(!lazyIframe) {return null;}
	const source = lazyIframe.getAttribute("src");
	if(!source) {return null;}
	const newIframe = document.createElement("iframe");
	newIframe.setAttribute("src", source);
	newIframe.setAttribute("frameborder","0");
	newIframe.setAttribute("allowfullscreen","allowfullscreen");
	newIframe.style.transition = "left ease 600ms, top ease 400ms, width ease 500ms, height ease 500ms";
	newIframe.width = "60px";
	newIframe.height = "60px";
	newIframe.classList.add("loading");
	return newIframe;
};

const destroyIframe = (lazyIframe:HTMLElement) => {
	const iframe = lazyIframe.querySelector<HTMLIFrameElement>("iframe");
	iframe?.remove();
};

const initLocations = () => document.querySelectorAll<HTMLElement>('[content-type="location"]').forEach(initLocation);

const hideCurLocation = () => {
	if(hideLocationCallback){
		hideLocationCallback();
	}
};

overlayCloseHandlers.push(hideCurLocation);
setTimeout(initLocations,0);
