import { getFirstParentSection } from "../../_helper";

import "./training.css";

export interface TrainingTask {
	difficulty: "0"|"1"|"2";
	introduction: string;
	taskUrl: string;
	socialForm: string;
	targetGroup: string;
	time: string;
	metadata:unknown;
}

export interface LearningGoal {
	id: string;
	level: number;
	slug: string;
	title: string;
	lft: number;
	editLink?: string;
	parent?: LearningGoal;
	tasks: TrainingTask[];
	childDepth?: number;
}

let learningGoals:Record<string, LearningGoal> = {};
let learningGoalPromise:Promise<void> | null = null;

export const getLearningGoals = async () => {
	// We do it this way so that even if we request learningGoals multiple times before the fetch
	// succeeded we only ever fetch the json file once
	if(learningGoalPromise){
		await learningGoalPromise;
		if(!Object.values(learningGoals).length){
			throw new Error("No learning goals even though we awaited learningGoalPromise");
		}
		return learningGoals;
	}

	try {
		let resolvePromise:((value: void | PromiseLike<void>) => void) | undefined;
		learningGoalPromise = new Promise(resolve => {
			resolvePromise = resolve;
		});
		learningGoals = await (await fetch("/learning-goals.json")).json();
		for(const id in learningGoals){
			const goal = learningGoals[id];
			// It's probably more convenient to have an actual reference instead of the id in the parent field
			if(typeof goal.parent === "string"){
				goal.parent = learningGoals[goal.parent];
			}
			// Here we clean up the structure of the metadata, might be nicer to move this to the backend though.
			for(const task of goal.tasks){
				const metadata = Array.isArray(task?.metadata) ? task?.metadata : [];
				task.socialForm = metadata[0]?.socialForm || "";
				task.targetGroup = metadata[0]?.targetGroup || "";
				task.time = metadata[0]?.time || "";
			}
		}
		if(resolvePromise){
			resolvePromise();
		}
		learningGoalPromise = null;
	} catch(e) {
		console.error("Error while fetching training tasks");
		console.error(e);
	}
	return learningGoals;
};

const getNextTrainingTask = async (cat:string) => {
	const goals = await getLearningGoals();
	const goal = goals[cat];
	if(!goal){
		throw new Error(`Can't find learningGoal with id=${cat}`);
	}
	const tasks = goal.tasks;
	// For now we just show a random task, this will have to become more fleshed out in future versions
	const i = Math.floor(Math.random() * tasks.length);
	const task = tasks[i];
	if(!task){
		throw new Error(`Couldn't determine the next task for goal=${goal.slug}`);
	}
	return task;
};

export const wordPressH5PUrlToIframeSrc = (rawurl:string) => {
	const url = new URL(rawurl);
	const id = url.searchParams.get("id");
	if(!id){
		throw new Error(`Couldn't determine H5P ID for ${rawurl}`);
	}
	const ret = `${url.origin}/wp-admin/admin-ajax.php?action=h5p_embed&id=${id}`;
	if(ret.startsWith("http://")){
		return 'https://'+ret.substring(7);
	} else {
		return ret;
	}
};

const initSingleTrainingTask = async (wrap:HTMLElement) => {
	const section = getFirstParentSection(wrap);
	const id = section?.getAttribute('content-type-id') || "";
	const cat = wrap.getAttribute('task-cat') || "";
	const count = parseInt(wrap.getAttribute('task-count') || "") || 0;
	if(!cat || !count){
		console.error(`Couldn't initialize training ${id}`);
	}

	const previousButton = document.createElement("button");
	previousButton.classList.add("training-task-button");
	previousButton.classList.add("working-task-button");
	previousButton.classList.add("working-task-prev");
	wrap.append(previousButton);

	const nextButton = document.createElement("button");
	nextButton.classList.add("training-task-button");
	nextButton.classList.add("working-task-button");
	nextButton.classList.add("working-task-next");
	wrap.append(nextButton);

	const startScreen = document.createElement("training-task-start-screen");
	wrap.append(startScreen);

	const startButton = document.createElement("button");
	startButton.innerHTML = "Passende Übungen<br/>für mich erstellen";

	startButton.classList.add("training-task-start-button");
	startScreen.append(startButton);

	const taskElementList:HTMLElement[] = [];
	const taskList:TrainingTask[] = [];
	let currentTaskIndex = -1;

	const showTask = (i:number) => {
		startScreen.remove();
		taskElementList.forEach((task:HTMLElement) => {
			task.classList.add("hidden");
		});
		if(!taskElementList[i]){
			const task = taskList[i];
			const iframe = document.createElement("iframe");
			iframe.setAttribute("frameborder","0");
			iframe.setAttribute("allowfullscreen","allowfullscreen");
			iframe.classList.add("h5p-iframe");
			iframe.src = wordPressH5PUrlToIframeSrc(task.taskUrl);

			const taskWrap = document.createElement("training-task");
			taskWrap.innerHTML = task.introduction;
			taskWrap.append(iframe);

			wrap.append(taskWrap);
			taskElementList[i] = taskWrap;
		}
		taskElementList[i].classList.remove('hidden');
	};

	const refreshPrevNextButtons = () => {
		if(hasPreviousTask()){
			previousButton.classList.remove("hidden");
		} else {
			previousButton.classList.add("hidden");
		}
		if(hasNextTask()){
			nextButton.classList.remove("hidden");
		} else {
			nextButton.classList.add("hidden");
		}
	};

	const hasNextTask = () => currentTaskIndex >= 0;
	const nextTask = async () => {
		if((currentTaskIndex+1) >= taskList.length){
			taskList.push(await getNextTrainingTask(cat));
		}
		showTask(++currentTaskIndex);
		refreshPrevNextButtons();
	};
	nextButton.onclick = nextTask;
	startButton.onclick = nextTask;

	const hasPreviousTask = () => currentTaskIndex > 0;
	const previousTask = () => {
		if(hasPreviousTask()){
			showTask(--currentTaskIndex);
		}
		refreshPrevNextButtons();
	};
	previousButton.onclick = previousTask;
	refreshPrevNextButtons();
};


setTimeout(() => {
	document.querySelectorAll('training-tasks').forEach(initSingleTrainingTask);
},0);
