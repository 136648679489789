import {IMessage, ISubmission} from '@digitale-lernwelten/ugm-client-lib';
import { deleteMessage, getMessages, markMessageAsRead, IMessageEvent } from "./messages";
import { getAllSubmissions } from '../submissions/submissions';
import { stripHref } from '../../_helper';

import "./messages-ui.css";

const refreshSingleMessageDom = (msgContainer:HTMLElement, msg:IMessage) => {
	const container = msgContainer.querySelector<HTMLDetailsElement>(`details.single-message[msg-id="${msg.id}"]`);
	if(container){
		if(msg.isRead){
			container.classList.add("hasread");
		}else{
			container.classList.remove("hasread");
		}
		container.setAttribute("updated-at", String(Number(new Date(msg.updatedAt))));
		return;
	}
	const msgDate = new Date(msg.createdAt).toLocaleDateString();
	if(!msgContainer){return;}
	const messageHtml = `<summary>
			<time class="message-date">${msgDate}</time>
			<div class="message-subject">${msg.subject}</div>
			<div class="message-delete" title="Nachricht Löschen"></div>
		</summary>
		<div class="message-body">${msg.body}</div>`;
	const ele = document.createElement("details");
	ele.classList.add("single-message");
	ele.setAttribute("updated-at", String(Number(new Date(msg.updatedAt))));
	if(msg.isRead){
		ele.classList.add("hasread");
	}
	ele.setAttribute("msg-id", msg.id);
	ele.innerHTML = messageHtml;
	msgContainer.prepend(ele);
};

const refreshSingleCorrectionDom = (msgContainer:HTMLElement, sub:ISubmission) => {
	const container = msgContainer.querySelector<HTMLElement>(`div.single-correction[sub-id="${sub.id}"]`);
	if(container){
		if(sub.correction?.read){
			container.classList.add("hasread");
		}else{
			container.classList.remove("hasread");
		}
		container.setAttribute("updated-at", String(Number(new Date(sub.updatedAt))));
		return;
	}
	const msgDate = new Date(sub.updatedAt).toLocaleDateString();
	if(!msgContainer){return;}
	const messageHtml = `<a href="${stripHref(sub.exerciseHref)}?showCorrection=${sub.id}" title="${sub.exerciseChapter} - ${sub.exerciseTitle}">
			<time class="message-date">${msgDate}</time>
			<div class="message-subject">${sub.exerciseTitle}</div>
		</a>`;
	const ele = document.createElement("div");
	ele.classList.add("single-correction");
	ele.setAttribute("updated-at", String(Number(new Date(sub.updatedAt))));
	if(sub.correction?.read){
		ele.classList.add("hasread");
	}
	ele.setAttribute("sub-id", sub.id);
	ele.innerHTML = messageHtml;
	msgContainer.prepend(ele);
};

const instanceOfSubmission = (object: any): object is ISubmission => {
	return 'exerciseHref' in object;
};

const refreshMessageDomElement = (e:IMessageEvent, container:HTMLElement) => {
	const msgs:Array<IMessage | ISubmission> = getMessages();
	const cat = msgs.concat(getAllSubmissions().filter(sub => sub.state === "corrected"));
	cat.sort((a,b) => (new Date(a.updatedAt).getTime()) - (new Date(b.updatedAt).getTime()));
	for(const o of cat){
		console.log(o);
		if(instanceOfSubmission(o)){
			refreshSingleCorrectionDom(container, o);
		} else {
			refreshSingleMessageDom(container, o);
		}
	}

	if(e && e.type === "delete"){
		const ele = container.querySelector<HTMLDetailsElement>(`details.single-message[msg-id="${e.id}"]`);
		if(ele){ ele.remove(); }
	}

	Array.from(container.querySelectorAll("details.single-message")).forEach(d => {
		const msgId = d.getAttribute("msg-id") || "";
		d.addEventListener("click", () => {
			markMessageAsRead(msgId);
			window.dispatchEvent(new Event("ugm-modal-message-update"));
		});
		const deleteButton = d.querySelector(".message-delete");
		if(deleteButton){
			deleteButton.addEventListener("click", (e) => {
				e.stopPropagation();
				e.preventDefault();
				if(d.classList.contains("deleted")){return;}
				d.classList.add("deleted");
				deleteMessage(msgId);
				setTimeout(() => d.remove(), 350);
			});
		}
	});
};

const refreshMessageDom = (e:IMessageEvent) => {
	document.querySelectorAll("msg-container").forEach((ele:HTMLElement) => {
		refreshMessageDomElement(e,ele);
	});
};

export const initMessageDom = () => {
	const e:IMessageEvent = {
		id:"",
		type:"init"
	};
	refreshMessageDom(e);
	window.dispatchEvent(new Event("ugm-header-update"));
};
window.addEventListener("ugm-modal-message-update", initMessageDom);
